import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import {connect} from "react-redux";
import React, {useCallback, useEffect, useImperativeHandle, useRef, useState} from "react";
import LoaderService from "../../../RetroAssets.System/LoaderService";
import {alert, confirm} from "devextreme/ui/dialog";
import apiClient from "../../../services/axios";
import cl from "../../../RetroAssets.System/Utilities";
import {DateBox, NumberBox, SelectBox, TextBox} from "devextreme-react";
import TransactionsComponent from "../../../RetroAssets.System/Components/TransactionsComponent";
import Validator, {RequiredRule} from "devextreme-react/validator";
import {ValidationGroup} from "devextreme-react";
import {Summary, TotalItem} from "devextreme-react/data-grid";
import AutoGenerateTransactions from "../../Popups/AutoGenerateTransactions";
import Image from "react-bootstrap/Image";
import TenancyContractCancellation from "./TenancyContractCancellation";
import ManageTenancyContractFileAttachments from "../../Popups/ManageTenancyContractFileAttachments";

const mapStateToProps = ({settings}) => ({
    dispatch: settings.dispatch,
});

const ManageTenancyContractDetails = React.forwardRef((props, componentReference) => {
    const {externalId, propertyExternalId, rowData, createNew, defaultCurrency, defaultTax} = props;
    const validationGroup = useRef(null);

    let contractSpanString = "_TenancyContractDetails";
    const [selectedContract, setSelectedContract] = useState({});

    const transactionSpanString = "TenancyContract_Transactions";
    const gridInstance = useRef(null);
    const transactionSearchRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const [gridColumns, setGridColumns] = useState([]);
    const [gridCustomization, setGridCustomization] = useState({});
    const [editedData, setEditedData] = useState([]);
    const [deletedTransactions, setDeletedTransactions] = useState([]);
    const [readOnlyAgentSelection, setReadOnlyAgentSelection] = useState(false);

    const [totalAmounts, setTotalAmounts] = useState({
        Rent: 0,
        Commission: 0,
        Deposit: 0,
        TaxTotal: 0,
    });
    const [allAgents, setAllAgents] = useState([]);
    const [allTaxes, setAllTaxes] = useState([]);

    const [readOnly, setReadOnly] = useState(true);
    const [dataUpdated, setDataUpdated] = useState(false);
    const [cancelContractDetails, setCancelContractDetails] = useState({
        show: false,
        externalId: "",
    });

    const [newItemId, setNewItemId] = useState(0);
    const [autoGenerateTransactionSettings, setAutoGenerateTransactionSettings] = useState({
        show: false,
    });
    const [faDbUpdateSettings, setFaDbUpdateSettings] = useState({
        gridData: [],
        spanString: '',
        editedData: [],
        deletedItems: [],
        attachmentInstances: []
    })
    const [manageFileAttachmentDetails, setManageFileAttachmentDetails] = useState({
        show: false,
        externalId: "",
        instantMode: false,
        readOnly: false
    })

    const summaryObject = () => {
        return (
            <Summary
                recalculateWhileEditing={true}
                calculateCustomSummary={calculateCustomSummary}
            >
                <TotalItem
                    name={"AllTotals"}
                    summaryType={"custom"}
                    valueFormat={"#,#00.00"}
                    displayFormat={"Total: {0}"}
                    showInColumn={"Total"}
                ></TotalItem>
            </Summary>
        );
    };

    const calculateCustomSummary = (e) => {
        if (e.name !== "AllTotals") return;
        if (e.summaryProcess === "start") {
            e.totalValue = 0;
            // setTotalAmounts({
            //     ...totalAmounts,
            //     Rent: 0,
            //     Commission: 0,
            //     Deposit: 0,
            //     TaxTotal: 0
            // })
        }
        if (e.summaryProcess === "calculate") {
            return;
            if (e.value["Cancelled"]) return;
            totalAmounts.TaxTotal += e.value["TaxAmount"];
            if (e.value["PaymentType"] === "security")
                totalAmounts.Deposit += e.value["Amount"];
            if (e.value["PaymentType"] === "rent")
                totalAmounts.Rent += e.value["Amount"];
            if (e.value["PaymentType"] === "commission")
                totalAmounts.Commission += e.value["Amount"];
            setTotalAmounts(totalAmounts);
        }
        if (e.summaryProcess === "finalize") {
        }
    };

    useEffect(() => {
        setSelectedContract(rowData());
        getData(false, createNew);
    }, []);

    useImperativeHandle(componentReference, () => {
        return {
            addNewTenancyContract() {
                return tempAddNewContract()
            }, instance() {
                return gridInstance.current.instance()
            }
        };
    }, []);

    function getData(showToast, forceAddNew = false, forceFetchId = null) {
        LoaderService.setData(true);
        let params = new URLSearchParams({
            externalId: forceFetchId == null ? externalId : forceFetchId,
        });
        apiClient
            .get("/Property/GetDetailsForTenancyContractPage?" + params)
            .then((response) => {
                try {
                    let data = JSON.parse(response.data);
                    if (forceAddNew) {
                        tempAddNewContract()
                    }

                    let transactions = data[0]["Transactions"];
                    setGridData(transactions[0]["Data"] ?? []);
                    calculateTotals(transactions[0]["Data"] ?? []);
                    let tSettings = transactions[1]["Settings"];
                    let cols = tSettings[0][transactionSpanString][0]["Columns"] ?? [];
                    let index = cols.findIndex((x) => x["dataField"] === "Tax");
                    if (index > -1) {
                        setAllTaxes(cols[index]["lookup"]["dataSource"]);
                    }
                    setGridColumns(cols);
                    setGridCustomization(
                        cl.enableDisableDataGridEditing(
                            tSettings[0][transactionSpanString][3]["GridCustomization"][0],
                            null
                        )
                    );
                    setAllAgents(data[1]["Agents"]);
                    validationGroup.current.instance().reset();
                    if (showToast) cl.showSuccessToast();
                    if (forceAddNew)
                        setReadOnly(false);
                    else
                        setReadOnly(true);
                    LoaderService.setData(false);
                } catch (e) {
                    LoaderService.setData(false);
                    return alert(response.data, "Error!");
                }
            })
            .catch((err) => {
                LoaderService.setData(false);
                console.log(err);
                return alert(err, "Error!");
            });
    }

    //#region Form Fields

    const formValueChangedHandler = (e) => {
        if (!e.event) return;
        let property = e.component.option("property");
        if (property === "Agent") {
            if (e.value) enableDisableAgentSelection(false);
            else enableDisableAgentSelection(true);
        }
        setSelectedContract({
            ...selectedContract,
            [property]: e.value,
        });
        setDataUpdated(true);
    };

    const tempAddNewContract = useCallback(() => {
        let keys = Object.keys(selectedContract);
        let tempRecord = {};
        keys.forEach((key) => {
            tempRecord[key] = null;
        });
        let id = newItemId;
        tempRecord["externalId"] = "NNNN" + id;
        tempRecord["Currency"] = selectedContract["Currency"];
        tempRecord["Tax"] = selectedContract["Tax"];
        tempRecord["Tenant"] = "";
        tempRecord["TenantId"] = "";

        setSelectedContract(tempRecord);
        id = id + 1;
        validationGroup.current.instance().reset();
        setTotalAmounts({
            ...totalAmounts,
            Rent: 0,
            Commission: 0,
            Deposit: 0,
            TaxTotal: 0,
        });
        if (readOnly) {
            enableDisableEditing(tempRecord);
        }
        setReadOnlyAgentSelection(false);
        setGridData([]);
        setNewItemId(id);
        setSelectedContract(tempRecord)
    }, [
        newItemId,
        selectedContract,
        totalAmounts,
        gridData,
        gridCustomization,
        readOnlyAgentSelection,
    ]);

    //#endregion

    //#region Page Toolbar

    const backToContractListing = () => {
        if (dataUpdated) {
            return confirm("You have unsaved changes that will be lost if you proceed.<br />Are you sure you wish to go back?", "Warning!").then(resp => {
                if (resp)
                    props.showMainPage()
            })
        }
        props.showMainPage()
    }

    const addNewRecord = () => {
        if (readOnly)
            return alert("Please enable editing to add a new record.", "Warning!");
        gridInstance.current.instance().addRow();
    };

    const searchTransactionsByText = (e) => {
        if (!e.event) return;
        gridInstance.current?.instance().searchByText(e.value ? e.value : null);
    };

    //#endregion

    //#region Data Grid Functions
    const rowValidating = (e) => {
        if (e.newData.hasOwnProperty("Temp_Paid")) return;

        if (e.newData.hasOwnProperty("PaymentType")) {
            if (e.newData["PaymentType"] === "security") {
                e.newData["Income"] = true;
                e.newData["Flag_SecurityDeposit"] = true;
                e.newData["Flag_Commission"] = false;
                e.newData["Flag_Rent"] = false;
            }
            if (e.newData["PaymentType"] === "rent") {
                e.newData["Income"] = true;
                e.newData["Flag_SecurityDeposit"] = false;
                e.newData["Flag_Commission"] = false;
                e.newData["Flag_Rent"] = true;
                if (selectedContract["Tax"]) e.newData["Tax"] = selectedContract["Tax"];
            }
            if (e.newData["PaymentType"] === "commission") {
                e.newData["Income"] = false;
                e.newData["Flag_SecurityDeposit"] = false;
                e.newData["Flag_Commission"] = true;
                e.newData["Flag_Rent"] = false;
            }
        }

        if (e.newData.hasOwnProperty("Total") || e.newData.hasOwnProperty("Tax")) {
            let taxRate = 0;
            if (e.newData.hasOwnProperty("Tax")) {
                if (e.newData.Tax) {
                    let index = allTaxes.findIndex((x) => x.externalId === e.newData.Tax);
                    if (index > -1) taxRate = allTaxes[index]["Rate"];
                }
            } else if (e.oldData !== undefined) {
                if (e.oldData.hasOwnProperty("Tax")) {
                    if (e.oldData.Tax) {
                        let index = allTaxes.findIndex(
                            (x) => x.externalId === e.oldData.Tax
                        );
                        if (index > -1) taxRate = allTaxes[index]["Rate"];
                    }
                }
            }

            let totalAmount = e.newData.hasOwnProperty("Total")
                ? e.newData["Total"]
                : e.oldData["Total"];
            let amount = 0;

            if (taxRate === 0) {
                e.newData["Amount"] = totalAmount;
                e.newData["TaxAmount"] = 0;
            } else {
                e.newData["Amount"] = totalAmount / (taxRate / 100 + 1);
                amount = e.newData["Amount"];

                e.newData["TaxAmount"] = (taxRate / 100) * parseFloat(amount);
            }
        }

        setEditedData(cl.rowValidatingEvent(e, editedData));
        setDataUpdated(true);
    };

    const clearFilter = () => {
        gridInstance.current?.instance().clearFilter();
        gridInstance.current?.instance().searchByText("");
        transactionSearchRef.current?.instance().option("value", null);
    };

    const enableDisableEditing = (data) => {
        if (readOnly) {
            setReadOnly(!readOnly);
            setGridCustomization(
                cl.enableDisableDataGridEditing(
                    gridCustomization,
                    gridInstance.current.instance()
                )
            );
            if (data["externalId"].includes("NNNN"))
                setReadOnlyAgentSelection(false);
        } else {
            setReadOnly(!readOnly);
            setGridCustomization(
                cl.enableDisableDataGridEditing(
                    gridCustomization,
                    gridInstance.current.instance()
                )
            );
            setReadOnlyAgentSelection(true);
        }
    };

    const newTransactionAdded = useCallback(
        (e) => {
            e.data["externalId"] = "NNNN" + newItemId;
            e.data["ID"] = "NNNN";
            e.data["Currency"] = selectedContract["Currency"];
            setNewItemId(newItemId + 1);
        },
        [newItemId, selectedContract]
    );

    const enableDisableAgentSelection = (isDisabled) => {
        let index = gridColumns.findIndex((x) => x["dataField"] === "PaymentType");
        gridColumns[index]["lookup"]["dataSource"][0]["disabled"] = isDisabled;
        setGridColumns(gridColumns);
        gridInstance.current.instance().repaint();
    };

    const deleteContractTransaction = useCallback(
        (e) => {
            if (e.row.data["Paid"])
                return alert("Cannot delete completed transactions.", "Warning!");

            confirm("Are you sure you wish to delete?", "Warning!").then((resp) => {
                if (resp) {
                    let index = editedData.findIndex(
                        (x) => x["externalId"] === e.row.key
                    );
                    if (index > -1) {
                        editedData.splice(index, 1);
                        setEditedData(editedData);
                    }

                    index = gridData.findIndex((x) => x["externalId"] === e.row.key);
                    if (index > -1) {
                        gridData.splice(index, 1);
                        gridInstance.current.instance().option("dataSource", gridData);
                        setGridData(gridData);
                    }
                    gridInstance.current.instance().repaint();
                    setDataUpdated(true);
                    if (e.row.key.includes("NNNN")) return;
                    deletedTransactions.push(e.row.key);
                    setDeletedTransactions(deletedTransactions);
                }
            });
        },
        [editedData, deletedTransactions, gridData]
    );

    const autoGenerateTransactions = () => {
        setAutoGenerateTransactionSettings({
            ...autoGenerateTransactionSettings,
            show: true,
        });
    };

    const generateTransactions = useCallback(
        (data) => {
            if (readOnly) enableDisableEditing(selectedContract);
            let count = gridData.length + 1;
            let firstDate = data.StartDate;
            let rows = data.NumberOfInstallments;
            let gap = data.MonthGap;
            let amount = data.Amount;
            let nwIndex = newItemId;
            for (let i = 0; i < rows; i++) {
                let month;
                if (i > 0) {
                    month = firstDate.getMonth() + gap;
                    firstDate = new Date(firstDate.setMonth(month));
                }
                let newRecord = {
                    ID: "NNNN",
                    Date: firstDate,
                    Amount: amount,
                    Currency: selectedContract["Currency"],
                    Remarks: "Rent #" + count.toString(),
                    externalId: "NNNN" + nwIndex,
                    TaxAmount: 0.0,
                    Total: amount,
                    Flag_PurchasePaymentPlan: true,
                };
                gridData.push(newRecord);
                editedData.push(newRecord);
                nwIndex++;
                count++;
            }
            setNewItemId(nwIndex);
            setGridData(gridData);
            setEditedData(editedData);
            setDataUpdated(true);
            gridInstance.current.instance().repaint();
            gridInstance.current.instance().option("dataSource", gridData);
        },
        [editedData, gridData, gridCustomization]
    );

    //#endregion

    //#region Database Update Functions
    const addUpdateContract = () => {
        let formData = new FormData();

        formData.append("PropertyExternalId", propertyExternalId); //"357458b0-e848-45f0-b83b-739aba9fbd3a")

        formData.append("TenancyContractData", JSON.stringify(selectedContract));
        formData.append("TenancyContractSpanString", contractSpanString);

        formData.append("Transactions", JSON.stringify(editedData));
        formData.append("TransactionsSpanString", transactionSpanString);
        formData.append("DeletedTransactions", JSON.stringify(deletedTransactions));

        formData.append('FileAttachmentData', JSON.stringify(faDbUpdateSettings.gridData));
        formData.append(
            'FileAttachmentEditedData',
            JSON.stringify([{spanString: faDbUpdateSettings.spanString, editedData: faDbUpdateSettings.editedData}]),
        );
        formData.append('DeletedFileAttachments', JSON.stringify(faDbUpdateSettings.deletedItems));
        let fa = faDbUpdateSettings.attachmentInstances;
        fa.forEach(val => {
            formData.append(val['uniqueId'], val, val['uniqueId']);
        });

        LoaderService.setData(true);
        apiClient
            .post("/Property/AddUpdateTenancyContract", formData)
            .then((response) => {
                if (response) {
                    try {
                        LoaderService.setData(false);
                        let resp = JSON.parse(response.data);
                        setEditedData([]);
                        setDataUpdated(false);
                        setDeletedTransactions([]);
                        if (resp[0].response) {
                            getData(true, false, resp[0].message);
                        } else {
                            return alert(resp[0].message, "Warning!");
                        }
                    } catch (e) {
                        LoaderService.setData(false);
                        return alert(response.data, "Error!");
                    }
                }
            })
            .catch((err) => {
                LoaderService.setData(false);
                console.log(err);
                return alert(err, "Error!");
            });
    };

    const checkCanTerminate = () => {
        if (selectedContract["Terminated"])
            return alert(
                "Selected contract has already been marked as terminated.",
                "Warning!"
            );

        if (!selectedContract["Active"])
            return alert(
                "Selected contract has already been terminated.",
                "Warning!"
            );

        if (selectedContract["NotPaid"]) {
            return confirm(
                "One or more transactions for this contract is left unpaid.<br />Are you sure you wish to mark contract as terminated?",
                "Warning!"
            ).then((resp) => {
                if (resp) return markContractAsTerminated();
            });
        }
        confirm(
            "Are you sure you wish to mark the contract as terminated?",
            "Warning!"
        ).then((resp) => {
            if (resp) return markContractAsTerminated();
        });
    };

    const markContractAsTerminated = () => {
        LoaderService.setData(true);
        apiClient
            .get("/Property/MarkTenancyContractAsTerminated")
            .then((response) => {
                try {
                    LoaderService.setData(false);
                    let resp = JSON.parse(response.data);
                    if (resp[0].response) {
                        getData(true);
                    } else {
                        return alert(resp[0].message, "Warning!");
                    }
                } catch (e) {
                    LoaderService.setData(false);
                    return alert(response.data, "Error!");
                }
            })
            .catch((err) => {
                LoaderService.setData(false);
                console.log(err);
                return alert(err, "Error!");
            });
    };

    const manageTerminationDetails = () => {
        if (selectedContract["NotPaid"] && selectedContract["Active"]) {
            return confirm(
                "One or more transactions for this contract is left unpaid.<br />Are you sure you wish to mark contract as terminated?",
                "Warning!"
            ).then((resp) => {
                if (resp) {
                    setCancelContractDetails({
                        ...cancelContractDetails,
                        show: true,
                        externalId: selectedContract["externalId"],
                    });
                }
            });
        }

        setCancelContractDetails({
            ...cancelContractDetails,
            show: true,
            externalId: selectedContract["externalId"],
        });
    };

    //#endregion

    const calculateTotals = (data) => {
        setTotalAmounts({
            ...totalAmounts,
            Rent: sum(
                data.filter((x) => x["Flag_Rent"]),
                "Amount"
            ),
            Commission: sum(
                data.filter((x) => x["Flag_Commission"]),
                "Amount"
            ),
            Deposit: sum(
                data.filter((x) => x["Flag_SecurityDeposit"]),
                "Amount"
            ),
            TaxTotal: sum(data, "TaxAmount"),
        });
    };

    function sum(items, prop) {
        return items.reduce(function (a, b) {
            return a + b[prop];
        }, 0);
    }

    const checkValidationsAndSaveData = () => {
        if (
            !gridInstance.current
                .instance()
                .getController("validating")
                .validate(true)
        ) {
            return alert(
                "Please fix all validation errors in the transactions before saving data.",
                "Warning!"
            );
        }
        if (!validationGroup.current.instance().validate().isValid) return;
        let ds = gridInstance.current.instance().option("dataSource");
        if (ds.length === 0)
            return alert(
                "Please add one or more rent, security deposit and commission transactions to save data.",
                "Warning!"
            );

        if (
            !selectedContract["Agent"] &&
            ds.findIndex((x) => x["PaymentType"] === "commission") > -1
        ) {
            return alert(
                "You have created one or more transactions of type <b>Agent Commission</b> without selecting an agent.<br />" +
                "Please select an agent or remove all transaction of type agent commission.",
                "Warning!"
            );
        }
        if (
            selectedContract["Agent"] &&
            ds.findIndex((x) => x["PaymentType"] === "commission") === -1
        ) {
            return confirm(
                "No commission transaction has been added for the selected agent." +
                "<br />Are you sure you wish to add the contract without a commission transaction?",
                "Warning!"
            ).then((resp) => {
                if (resp) {
                    return addUpdateContract();
                }
            });
        }
        if (!selectedContract["Agent"]) {
            return confirm(
                "No agent added against the selected contract.<br />Are you sure you wish to add the contract without an agent?",
                "Warning!"
            ).then((resp) => {
                if (resp) return addUpdateContract();
            });
        }
        addUpdateContract();
    };

    return (
        <>
            <ManageTenancyContractFileAttachments
                show={manageFileAttachmentDetails.show}
                externalId={manageFileAttachmentDetails.externalId}
                instantMode={manageFileAttachmentDetails.instantMode}
                readOnly={manageFileAttachmentDetails.readOnly}
                handleClose={() => {
                    setManageFileAttachmentDetails({
                        ...manageFileAttachmentDetails,
                        show: false,
                    });
                }}
                isInstantUpdated={() => {
                    props.isUpdated()
                }}
                isUpdated={(gridData, spanString, editedData, deletedItems, attachmentInstances) => {
                    setDataUpdated(true);
                    setFaDbUpdateSettings({
                        ...faDbUpdateSettings,
                        gridData: gridData,
                        spanString: spanString,
                        editedData: editedData,
                        deletedItems: deletedItems,
                        attachmentInstances: attachmentInstances
                    })
                }}
            ></ManageTenancyContractFileAttachments>
            <TenancyContractCancellation
                show={cancelContractDetails.show}
                externalId={cancelContractDetails.externalId}
                handleClose={() => {
                    setCancelContractDetails({
                        ...cancelContractDetails,
                        show: false,
                    });
                }}
                isUpdated={() => {
                    props.isUpdated()
                }}
            ></TenancyContractCancellation>
            <AutoGenerateTransactions
                show={autoGenerateTransactionSettings.show}
                handleClose={() =>
                    setAutoGenerateTransactionSettings({
                        ...autoGenerateTransactionSettings,
                        show: false,
                    })
                }
                isUpdated={(data) => generateTransactions(data)}
            ></AutoGenerateTransactions>
            <div className="rounded p-3 border border-secondary mb-3">
                <div
                    className="search-actions-header d-flex flex-wrap flex-xl-nowrap justify-content-between align-items-center">
                    <div className="d-sm-flex align-items-center mb-3">
                        <Button
                            variant="outline-primary"
                            className="me-3"
                            onClick={backToContractListing}
                        >
                            <Image src="/svg/chevron-left-green.svg"
                                   alt="refresh" width="20"
                                   height="20"/>
                            Back to Contract Listing
                        </Button>
                    </div>
                    <div className="d-flex flex-wrap flex-xl-nowrap">
                        <Button
                            variant="outline-primary"
                            className="mb-3 me-3"
                            onClick={() => {
                                setManageFileAttachmentDetails({
                                    ...manageFileAttachmentDetails,
                                    show: true,
                                    externalId: externalId,
                                    instantMode: false,
                                    readOnly: readOnly
                                })
                            }}
                        >
                            {readOnly ? "View File Attachments" : "Manage File Attachments"}
                        </Button>
                        <Button
                            variant="outline-primary"
                            className="mb-3 me-3"
                            onClick={() => {
                                enableDisableEditing(selectedContract);
                            }}
                        >
                            {readOnly ? "Enable Editing" : "Disable Edit"}
                        </Button>
                        <Button
                            variant="outline-primary"
                            className="mb-3 me-3"
                            onClick={checkValidationsAndSaveData}
                            disabled={!dataUpdated}
                        >
                            Save Changes
                        </Button>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-primary">
                                Termination Options
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={checkCanTerminate}>
                                    Mark As Terminated
                                </Dropdown.Item>
                                <Dropdown.Item onClick={manageTerminationDetails}>
                                    Manage Termination Details
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className="card-body p-0">
                    <ValidationGroup ref={validationGroup}>
                        <div className="row">
                            {/*TenantId*/}
                            <div className="col-sm-6 col-md-3">
                                <Form.Group className="mb-2">
                                    <Form.Label>Tenant Id</Form.Label>
                                    <TextBox
                                        inputAttr={{class: "form-control"}}
                                        elementAttr={{class: "input-group"}}
                                        placeholder="Tenant Id"
                                        property="TenantId"
                                        value={selectedContract["TenantId"]}
                                        readOnly={readOnly}
                                        onValueChanged={formValueChangedHandler}
                                    ></TextBox>
                                </Form.Group>
                            </div>
                            {/*Tenant*/}
                            <div className="col-sm-6 col-md-3">
                                <Form.Group className="mb-2">
                                    <Form.Label>Tenant Name</Form.Label>
                                    <TextBox
                                        inputAttr={{class: "form-control"}}
                                        elementAttr={{class: "input-group"}}
                                        placeholder="Tenant"
                                        property="Tenant"
                                        value={selectedContract["Tenant"]}
                                        readOnly={readOnly}
                                        onValueChanged={formValueChangedHandler}
                                    >
                                        <Validator>
                                            <RequiredRule message="Tenant Name cannot be empty"></RequiredRule>
                                        </Validator>
                                    </TextBox>
                                </Form.Group>
                            </div>
                            {/*TenantContact*/}
                            <div className="col-sm-6 col-md-3">
                                <Form.Group className="mb-2">
                                    <Form.Label>Tenant Contact</Form.Label>
                                    <TextBox
                                        inputAttr={{class: "form-control"}}
                                        elementAttr={{class: "input-group"}}
                                        placeholder="Tenant Contact"
                                        property="TenantContact"
                                        value={selectedContract["TenantContact"]}
                                        readOnly={readOnly}
                                        onValueChanged={formValueChangedHandler}
                                    ></TextBox>
                                </Form.Group>
                            </div>
                            {/*TenantEmail*/}
                            <div className="col-sm-6 col-md-3">
                                <Form.Group className="mb-2">
                                    <Form.Label>Tenant Email</Form.Label>
                                    <TextBox
                                        inputAttr={{class: "form-control"}}
                                        elementAttr={{class: "input-group"}}
                                        placeholder="Tenant Email Id"
                                        property="TenantEmail"
                                        value={selectedContract["TenantEmail"]}
                                        readOnly={readOnly}
                                        onValueChanged={formValueChangedHandler}
                                    ></TextBox>
                                </Form.Group>
                            </div>
                            {/*Agent*/}
                            <div className="col-sm-6 col-md-3">
                                <Form.Group className="mb-2">
                                    <Form.Label>Agent</Form.Label>
                                    <SelectBox
                                        displayExpr="Name"
                                        valueExpr="externalId"
                                        dataSource={allAgents}
                                        inputAttr={{class: "form-control"}}
                                        elementAttr={{class: "input-group"}}
                                        placeholder="Agent"
                                        property="Agent"
                                        value={selectedContract["Agent"]}
                                        readOnly={readOnlyAgentSelection}
                                        onValueChanged={formValueChangedHandler}
                                    >
                                        <Validator>
                                            <RequiredRule message="Tenant Email Id cannot be empty"></RequiredRule>
                                        </Validator>
                                    </SelectBox>
                                </Form.Group>
                            </div>
                            {/*StartDate*/}
                            <div className="col-sm-6 col-md-3">
                                <Form.Group className="mb-2">
                                    <Form.Label>Start Date</Form.Label>
                                    <DateBox
                                        displayFormat="dd/MM/yyyy"
                                        inputAttr={{class: "form-control"}}
                                        elementAttr={{class: "input-group"}}
                                        placeholder="Start Date"
                                        property="StartDate"
                                        value={selectedContract["StartDate"]}
                                        readOnly={readOnly}
                                        onValueChanged={formValueChangedHandler}
                                    >
                                        <Validator>
                                            <RequiredRule message="Start Date cannot be empty"></RequiredRule>
                                        </Validator>
                                    </DateBox>
                                </Form.Group>
                            </div>
                            {/*EndDate*/}
                            <div className="col-sm-6 col-md-3">
                                <Form.Group className="mb-2">
                                    <Form.Label>End Date</Form.Label>
                                    <DateBox
                                        displayFormat="dd/MM/yyyy"
                                        inputAttr={{class: "form-control"}}
                                        elementAttr={{class: "input-group"}}
                                        placeholder="End Date"
                                        property="EndDate"
                                        value={selectedContract["EndDate"]}
                                        readOnly={readOnly}
                                        onValueChanged={formValueChangedHandler}
                                    >
                                        <Validator>
                                            <RequiredRule message="End Date cannot be empty"></RequiredRule>
                                        </Validator>
                                    </DateBox>
                                </Form.Group>
                            </div>
                            {/*CancellationDate*/}
                            <div className="col-sm-6 col-md-3">
                                <Form.Group className="mb-2">
                                    <Form.Label>Cancellation Date</Form.Label>
                                    <DateBox
                                        displayFormat="dd/MM/yyyy"
                                        inputAttr={{class: "form-control"}}
                                        elementAttr={{class: "input-group"}}
                                        placeholder="Cancellation Date"
                                        property="CancellationDate"
                                        value={selectedContract["CancellationDate"]}
                                        readOnly={true}
                                    ></DateBox>
                                </Form.Group>
                            </div>
                            {/*Description*/}
                            <div className="col-sm-12 col-md-6">
                                <Form.Group className="mb-2">
                                    <Form.Label>Description</Form.Label>
                                    <TextBox
                                        inputAttr={{class: "form-control"}}
                                        elementAttr={{class: "input-group"}}
                                        placeholder="Description"
                                        property="Description"
                                        value={selectedContract["Description"]}
                                        readOnly={readOnly}
                                        onValueChanged={formValueChangedHandler}
                                    ></TextBox>
                                </Form.Group>
                            </div>
                            {/*Remark*/}
                            <div className="col-sm-12 col-md-6">
                                <Form.Group className="mb-2">
                                    <Form.Label>Remark</Form.Label>
                                    <TextBox
                                        inputAttr={{class: "form-control"}}
                                        elementAttr={{class: "input-group"}}
                                        placeholder="Remark"
                                        property="Remark"
                                        value={selectedContract["Remark"]}
                                        readOnly={readOnly}
                                        onValueChanged={formValueChangedHandler}
                                    ></TextBox>
                                </Form.Group>
                            </div>
                            {/*Rent*/}
                            <div className="col-sm-6 col-md-3">
                                <Form.Group className="mb-2">
                                    <Form.Label>Total Rent [Excl. Taxes]</Form.Label>
                                    <NumberBox
                                        format="#,#00.00"
                                        inputAttr={{class: "form-control"}}
                                        elementAttr={{class: "input-group"}}
                                        placeholder="Rent"
                                        value={totalAmounts["Rent"]}
                                        readOnly={true}
                                    ></NumberBox>
                                </Form.Group>
                            </div>
                            {/*Commission*/}
                            <div className="col-sm-6 col-md-3">
                                <Form.Group className="mb-2">
                                    <Form.Label>Total Commission [Excl. Taxes]</Form.Label>
                                    <NumberBox
                                        format="#,#00.00"
                                        inputAttr={{class: "form-control"}}
                                        elementAttr={{class: "input-group"}}
                                        placeholder="Commission"
                                        value={totalAmounts["Commission"]}
                                        readOnly={true}
                                    ></NumberBox>
                                </Form.Group>
                            </div>
                            {/*Deposit*/}
                            <div className="col-sm-6 col-md-3">
                                <Form.Group className="mb-2">
                                    <Form.Label>Total Deposit [Excl. Taxes]</Form.Label>
                                    <NumberBox
                                        format="#,#00.00"
                                        inputAttr={{class: "form-control"}}
                                        elementAttr={{class: "input-group"}}
                                        placeholder="Deposit"
                                        value={totalAmounts["Deposit"]}
                                        readOnly={true}
                                    ></NumberBox>
                                </Form.Group>
                            </div>
                            {/*TaxTotal*/}
                            <div className="col-sm-6 col-md-3">
                                <Form.Group className="mb-2">
                                    <Form.Label>Total Tax</Form.Label>
                                    <NumberBox
                                        format="#,#00.00"
                                        inputAttr={{class: "form-control"}}
                                        elementAttr={{class: "input-group"}}
                                        placeholder="Total Tax"
                                        value={totalAmounts["TaxTotal"]}
                                        readOnly={true}
                                    ></NumberBox>
                                </Form.Group>
                            </div>
                        </div>
                    </ValidationGroup>
                </div>
            </div>
            <div className="rounded p-3 border border-secondary">
                <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-2">
                    <h4 className="fs-4 fw-medium mb-3 mb-sm-0">
                        <b>Contract Transactions</b>
                    </h4>
                    <div className="d-flex">
                        <Button
                            variant="outline-primary"
                            className="flex-shrink-0 me-3"
                            onClick={addNewRecord}
                        >
                            Add
                        </Button>
                        <Button
                            variant="outline-primary"
                            className="flex-shrink-0 me-3"
                            onClick={autoGenerateTransactions}
                        >
                            Auto Generate Transactions
                        </Button>
                        <Button
                            variant="outline-primary"
                            className="flex-shrink-0 me-3"
                            onClick={clearFilter}
                        >
                            Clear Filter
                        </Button>
                        <InputGroup className="me-3 search-input">
                            <TextBox
                                ref={transactionSearchRef}
                                placeholder="Search Transactions..."
                                mode="search"
                                inputAttr={{class: "form-control temp"}}
                                elementAttr={{class: "search-input input-group"}}
                                width="100%"
                                onValueChanged={searchTransactionsByText}
                            ></TextBox>
                        </InputGroup>
                    </div>
                </div>
                <TransactionsComponent
                    ref={gridInstance}
                    data={gridData}
                    columns={gridColumns}
                    gridCustomization={gridCustomization}
                    height={500}
                    income={false}
                    expense={false}
                    tenancyContract={true}
                    rowValidatingEvent={rowValidating}
                    summaryObject={summaryObject}
                    newRowAdded={newTransactionAdded}
                    deleteContractTransaction={(e) => deleteContractTransaction(e)}
                    isUpdated={() => getData(true)}
                ></TransactionsComponent>
            </div>
        </>
    );
});

export default connect(mapStateToProps, null, null, {forwardRef: true})(ManageTenancyContractDetails)
