import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import {Column, DataGrid} from "devextreme-react/data-grid";
import {Template, Popover} from "devextreme-react";
import {MoreOptionsButton, ContextMenuTemplate} from "../MoreOptionsButton";
import cl from "../Utilities";
import ViewPropertyDetails from "../../RetroAssets/Popups/ViewPropertyDetail";
import ViewPropertyPhotos from "../../RetroAssets/Popups/ViewPropertyPhotos";
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";
import {alert, confirm} from 'devextreme/ui/dialog'
import LoaderService from "../LoaderService";
import apiClient from "../../services/axios";
import ManagePropertyDetails from "../../RetroAssets/Popups/ManagePropertyDetails";

const mapStateToProps = ({settings}) => ({
    dispatch: settings.dispatch
})

const PropertiesComponent = React.forwardRef((props, componentReference) => {
    const navigate = useNavigate();
    const popoverRef = useRef(null)

    const gridInstance = useRef(null)

    const {data, columns, gridCustomization, height, dispatch} = props
    const [propertyListData, setPropertyListData] = useState([])
    const [propertyListColumns, setPropertyListColumns] = useState([])
    const [propertyListGridCustomization, setPropertyListGridCustomization] = useState({})

    const [viewPropertySettings, setViewPropertySettings] = useState({
        show: false,
        externalId: ''
    })

    const [viewPropertyPhotos, setViewPropertyPhotos] = useState({
        show: false,
        externalId: '',
        Name: ''
    })

    const buttons = [{
        text: 'View',
        icon: 'customeye',
        onClick: function (e) {
            setViewPropertySettings({
                ...viewPropertySettings,
                show: true,
                externalId: e.row.key
            })
        }
    }]
    const [managePropertyDetailSettings, setManagePropertyDetailSettings] = useState({
        show: false,
        externalId: '',
        readOnly: false
    })

    useEffect(() => {
        setPropertyListData(data)
        setPropertyListGridCustomization(gridCustomization)
        setPropertyListColumns(columns)
    }, [data, columns, gridCustomization])

    const changeSetting = (setting, value) => {
        dispatch({
            type: 'settings/CHANGE_SETTING',
            payload: {
                setting,
                value,
            },
        });
    };

    const cellPrepared = e => {
        cl.setObjectColor(e)
        cl.setHeaderTooltip(e)
    }

    useImperativeHandle(componentReference, () => {
        return {
            addNewProperty() {
                setManagePropertyDetailSettings({
                    ...managePropertyDetailSettings,
                    show: true,
                    externalId: "",
                    readOnly: false
                })
            },
            instance() {
                return gridInstance.current.instance()
            }

        };
    }, []);

    const contextMenu = e => {
        if (!e.row) return;
        if (e.row.rowType !== 'data') return
        e.items = []
        e.component.selectRows(e.row.key)

        //View Details
        e.items.push({
            text: "View Details",
            icon: "eye",
            template: "contextMenuTemplate",
            onClick: function () {
                setViewPropertySettings({
                    ...viewPropertySettings,
                    show: true,
                    externalId: e.row.key
                })
            }
        })

        //Edit Details
        e.items.push({
            text: "Edit Details",
            icon: "edit",
            template: "contextMenuTemplate",
            onClick: function () {
                setManagePropertyDetailSettings({
                    ...managePropertyDetailSettings,
                    show: true,
                    externalId: e.row.key,
                    readOnly: false
                })
            }
        })

        //View Property Photos
        e.items.push({
            beginGroup: true,
            text: "View Property Photos",
            icon: "file",
            template: "contextMenuTemplate",
            onClick: function () {
                setViewPropertyPhotos({
                    ...viewPropertyPhotos,
                    show: true,
                    externalId: e.row.key,
                    Name: e.row.data["Name"]
                })
            }
        })

        //Tenancy Contracts
        e.items.push({
            beginGroup: true,
            text: "Tenancy Contracts",
            icon: "clipboard",
            template: "contextMenuTemplate",
            onClick: function () {
                changeSetting("SettingsForTenancyContract", JSON.stringify(e.row.data))
                navigate("/property/tenancy-contract-details")
            }
        })

        //Expenses
        e.items.push({
            beginGroup: true,
            text: "Expenses",
            icon: "coins",
            template: "contextMenuTemplate",
            onClick: function () {
                return settingsForExpenses(e.row.data)
            }
        })

        //Payment Plan
        e.items.push({
            beginGroup: true,
            text: "Purchase Plan",
            icon: "notification-text",
            template: "contextMenuTemplate",
            onClick: function () {
                return settingsForPaymentPlan(e.row.data)
            }
        })

        //Mark As Sold
        e.items.push({
            beginGroup: true,
            icon: "sold",
            template: "contextMenuTemplate",
            text: "Mark As Sold",
            onClick: function () {
                confirm("Are you sure you wish to mark the selected property as sold?", "Warning!").then(resp => {
                    if (resp) {
                        LoaderService.setData(true)
                        let params = new URLSearchParams({
                            externalId: e.row.key
                        })
                        apiClient
                            .get('/Property/MarkPropertyAsSold?' + params)
                            .then(response => {
                                try {
                                    LoaderService.setData(false)
                                    let resp = JSON.parse(response.data)
                                    if (resp[0].response) {
                                        props.isUpdated()
                                    } else {
                                        return alert(resp[0].message, 'Warning!')
                                    }
                                } catch (e) {
                                    LoaderService.setData(false)
                                    return alert(response.data, 'Error!')
                                }
                            })
                            .catch(err => {
                                LoaderService.setData(false)
                                console.log(err)
                                return alert(err, 'Error!')
                            })

                    }
                })
            }
        })
    }

    function settingsForPaymentPlan(data) {
        if (!data["PurchasePaymentPlan"])
            return alert("Cannot manage purchase payment transactions for properties that are not<br />" +
                "marked as under purchase payment.", "Warning!")

        changeSetting("SettingsForPurchasePayment", {
            externalId: data["externalId"],
            Name: data["Name"],
            Builder: data["Builder"],
            DisplayPurchaseDate: data["DisplayPurchaseDate"],
            PropertyStatus: data["Status"],
            PurchasePaymentPlan: data["PurchasePaymentPlan"],
            Available: data["Available"],
            Occupied: data["Occupied"],
            Currency: data["CurrencyExternalId"]
        })
        navigate("/property/purchase-payment")
    }

    function settingsForExpenses(data) {
        changeSetting("SettingsForPropertyExpenses", {
            externalId: data['externalId'],
            Name: data["Name"],
            Builder: data["Builder"],
            DisplayPurchaseDate: data["DisplayPurchaseDate"],
            PropertyStatus: data["Status"],
            PurchasePaymentPlan: data["PurchasePaymentPlan"],
            Available: data["Available"],
            Occupied: data["Occupied"],
            Currency: data["CurrencyExternalId"]
        })
        navigate("/property/property-expenses")
    }

    const rowClickEvent = e => {
        popoverRef.current.instance().option("target", e.rowElement)
        popoverRef.current.instance().show();
    }

    const RenderPopoverTemplate = () => {
        const changeColor = (colorCode) => {
            let selectedRowId = gridInstance.current.instance().getSelectedRowKeys()[0];

            let params = new URLSearchParams({
                objectName: "Property",
                externalId: selectedRowId,
                color: colorCode,
                remove: colorCode === null
            })
            apiClient
                .get('/Shared/UpdateObjectColor?' + params)
                .then(response => {
                    try {
                        LoaderService.setData(false)
                        let resp = JSON.parse(response.data)
                        if (resp[0].response) {
                            let index = propertyListData.findIndex(x => x.externalId === selectedRowId)
                            if (index > -1) {
                                propertyListData[index]["Color"] = colorCode
                                setPropertyListData(propertyListData)
                                gridInstance.current.instance().refresh()
                            }

                        } else {
                            return alert(resp[0].message, 'Warning!')
                        }
                        LoaderService.setData(false)
                    } catch (e) {
                        LoaderService.setData(false)
                        return alert(response.data, 'Error!')
                    }

                })
                .catch(err => {
                    LoaderService.setData(false)
                    console.log(err)
                    return alert(err, 'Error!')
                })
        }
        return (
            <>
                <span className="dot dot-color1" onClick={() => changeColor("#D2E2FF")}></span>
                <span className="dot dot-color2" onClick={() => changeColor("#FEE8B3")}></span>
                <span className="dot dot-color3" onClick={() => changeColor("#B1E3CE")}></span>
                <span className="dot dot-color4" onClick={() => changeColor("#D4D4D4")}></span>
                <span className="dot dot-color5" onClick={() => changeColor("#FFCFAF")}></span>
                <span className="dot dot-color6" onClick={() => changeColor("#EDD7FF")}></span>
                <span className="dot dot-color7" onClick={() => changeColor("#FFA7A7")}></span>
                <span className="dot dot-none" onClick={() => changeColor(null)}> </span>
            </>
        )
    }

    return (
        <div ref={componentReference}>
            <Popover
                ref={popoverRef}
                showEvent="dxclick"
                hideOnOutsideClick={true}
                width={180}
                contentRender={RenderPopoverTemplate}
            >
            </Popover>
            <DataGrid
                ref={gridInstance}
                dataSource={propertyListData}
                {...propertyListGridCustomization}
                keyExpr="externalId"
                height={height}
                onContextMenuPreparing={contextMenu}
                onRowClick={rowClickEvent}
                onCellPrepared={cellPrepared}
            >
                {propertyListColumns.map((column, i) => (
                    cl.generateReactColumnsFromJson(column)
                ))}
                {propertyListColumns.map((column, i) => (
                    cl.generateActionButtonColumn(column)
                ))}
                {columns.findIndex(x => x.cellTemplate === "myTemplate") > -1 ? <></> :
                    <Column key={"D8253C3C-CB82-4E4D-80B2-D13827814B2A"}
                            type={"buttons"}
                            width={"5%"}
                            buttons={buttons}
                            fixed={false}
                            fixedPosition={"right"}
                            showInColumnChooser={false}
                            actionColumn={true}
                    ></Column>}
                <Template name="myTemplate" render={MoreOptionsButton}/>
                <Template name="contextMenuTemplate" render={ContextMenuTemplate}/>
                <Template name="propertyNameTemplate" render={PropertyNameColumn}/>
                <Template name="statusTemplate" render={StatusColumn}/>
                <Template name={"contractStatusTemplate"} render={ContractStatusColumn}/>
            </DataGrid>
            <ViewPropertyDetails
                show={viewPropertySettings.show}
                externalId={viewPropertySettings.externalId}
                handleClose={() => setViewPropertySettings({...viewPropertySettings, show: false})}
                invokeViewPropertyDetails={(id) => {
                    setManagePropertyDetailSettings({
                        ...managePropertyDetailSettings,
                        show: true,
                        externalId: id,
                        readOnly: false
                    })
                }}
                invokeTenancyContract={(id) => {
                    let index = propertyListData.findIndex(x => x['externalId'] === id);
                    if (index === -1) return alert("Unable to find id for invoking tenancy contract.", "Warning!")

                    changeSetting("SettingsForTenancyContract", JSON.stringify(propertyListData[index]))
                    navigate("/property/tenancy-contract-details")
                }}
                invokePropertyExpenses={(id) => {
                    let index = propertyListData.findIndex(x => x['externalId'] === id);
                    if (index === -1) return alert("Unable to find id for invoking property expenses.", "Warning!")

                    return settingsForExpenses(propertyListData[index])
                }}
                invokePaymentTransactions={(id) => {
                    let index = propertyListData.findIndex(x => x['externalId'] === id);
                    if (index === -1) return alert("Unable to find id for invoking purchase payment transactions.", "Warning!")

                    return settingsForPaymentPlan(propertyListData[index])
                }}


            ></ViewPropertyDetails>
            <ViewPropertyPhotos
                show={viewPropertyPhotos.show}
                externalId={viewPropertyPhotos.externalId}
                name={viewPropertyPhotos.Name}
                handleClose={() => setViewPropertyPhotos({...viewPropertyPhotos, show: false})}
            ></ViewPropertyPhotos>
            <ManagePropertyDetails
                show={managePropertyDetailSettings.show}
                externalId={managePropertyDetailSettings.externalId}
                readOnly={managePropertyDetailSettings.readOnly}
                handleClose={() => setManagePropertyDetailSettings({...managePropertyDetailSettings, show: false})}
                isUpdated={() => props.isUpdated()}
            ></ManagePropertyDetails>
        </div>
    )
})

export default connect(mapStateToProps, null, null, {forwardRef: true})(PropertiesComponent)

const PropertyNameColumn = (props) => {
    return (<div key={props.key}>
        <div className="col-12"><b>{props.data["Name"]}</b></div>
        <div className="col-12">{props.data["Unit"]}</div>
    </div>)
}

const StatusColumn = (props) => {
    if (props.data["PurchasePaymentPlan"]) { //Payment Plan
        return (<label key={props.key} className="form-check-label" htmlFor="ps-na"><span
            className="status-color na"></span>{props.data["Status"]}</label>)
    } else if (!props.data["Available"]) { //Not Available
        return (<label key={props.key} className="form-check-label" htmlFor="ps-unoccupied"><span
            className="status-color unoccupied"></span>{props.data["Status"]}</label>)
    } else if (!props.data["Occupied"]) { //Unoccupied
        return (<label key={props.key} className="form-check-label" htmlFor="ps-overdue"><span
            className="status-color overdue"></span>{props.data["Status"]}</label>)
    } else if (props.data["Occupied"]) {//Occupied
        return (<label key={props.key} className="form-check-label" htmlFor="ps-occupied"><span
            className="status-color occupied"></span>{props.data["Status"]}</label>)
    }
}

const ContractStatusColumn = props => {
    if (props.data["ContractStatus"] === "N/A") { //N/A
        return (<label key={props.key} className="form-check-label"><span
            className="status-color"></span>{props.data["ContractStatus"]}</label>)
    } else if (props.data["ContractStatus"] === "TERMINATED") { //TERMINATED
        return (<label key={props.key} className="form-check-label"><span
            className="status-color"></span>{props.data["ContractStatus"]}</label>)
    } else if (props.data["ContractStatus"] === "PENDING TERMINATION DETAILS") { //PENDING TERMINATION DETAILS
        return (<label key={props.key} className="form-check-label"><span
            className="status-color na"></span>{props.data["ContractStatus"]}</label>)
    } else if (props.data["ContractStatus"] === "OVERDUE") { //OVERDUE
        return (<label key={props.key} className="form-check-label"><span
            className="status-color overdue"></span>{props.data["ContractStatus"]}</label>)
    } else if (props.data["ContractStatus"] === "DUE") { //DUE
        return (<label key={props.key} className="form-check-label"><span
            className="status-color na"></span>{props.data["ContractStatus"]}</label>)
    } else if (props.data["ContractStatus"] === "OCCUPIED") { //OCCUPIED
        return (<label key={props.key} className="form-check-label"><span
            className="status-color occupied"></span>{props.data["ContractStatus"]}</label>)
    }
}