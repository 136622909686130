import Modal from "react-bootstrap/Modal";
import React, {useEffect, useState, useRef} from "react";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import apiClient from '../../services/axios/index'
import LoaderService from "../../RetroAssets.System/LoaderService";
import {alert} from "devextreme/ui/dialog";
import Form from "react-bootstrap/Form";
import {TextBox, SelectBox, DateBox, ValidationGroup, NumberBox} from "devextreme-react";
import Validator, {RequiredRule, CustomRule} from 'devextreme-react/validator';
import cl from "../../RetroAssets.System/Utilities";

function RecordPayment(props) {
    const {show, externalId, paymentExternalId, date} = props
    const [showModal, setShowModal] = useState(false);

    const [transactionDetails, setTransactionDetails] = useState({})
    const [paymentDetails, setPaymentDetails] = useState({})

    const [accounts, setAccounts] = useState([])
    const statusRef = useRef(null)
    const typeRef = useRef(null)
    const validationGroup = useRef(null)

    const modalClose = () => {
        setTransactionDetails({})
        props.handleClose()
        setTransactionDetails({})
        setShowModal(false);
    }

    useEffect(() => {
        if (!show) return
        LoaderService.setData(true)
        let params = new URLSearchParams({
            externalId: externalId,
            paymentExternalId: paymentExternalId
        })
        apiClient
            .get('/Transactions/GetAllDataForPayment?' + params)
            .then(response => {
                try {
                    debugger
                    let data = JSON.parse(response.data)
                    const tData = data[0]["Transaction"]["Data"];
                    setTransactionDetails(tData)
                    let pData = data[1]["Payment"]["Data"]
                    if (!paymentExternalId) {
                        pData["Transaction"] = externalId
                        if (tData["Temp_Paid"] && tData["Temp_PaidDate"])
                            pData["Date"] = new Date(tData["Temp_PaidDate"].replace('T', ' '));
                        pData["Amount"] = tData["Balance"]
                    } else {
                        pData["Account"] = cl.getEcoIdFromExternalId(pData["Account"])
                    }
                    setPaymentDetails(pData)
                    setAccounts(data[2]["Accounts"])
                    setShowModal(true)
                    setTimeout(() => {
                        if (tData["Color_Status"]) {
                            let styleString = "color:" + tData["Color_Status"] + "; font-weight: bold;"
                            statusRef.current.instance().option("inputAttr", {style: styleString});
                        }
                        if (tData["Income"])
                            typeRef.current.instance().option("inputAttr", {style: "color: rgba(0, 128, 0, 0.53); font-weight: bold;"});
                        else
                            typeRef.current.instance().option("inputAttr", {style: "color: rgba(255, 0, 0, 1); font-weight: bold;"});
                        validationGroup.current.instance().reset()
                    }, 100)
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }

            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }, [show])

    const valueChangeHandler = e => {
        if (!e.event) return
        let prop = e.component.option("property")
        setPaymentDetails({
            ...paymentDetails,
            [prop]: e.value
        })
    }

    const customValidation = e => {
        let prop = e.rule.property
        if (prop === "Amount") {
            if (paymentExternalId)
                return true


            if (e.value <= 0 || e.value === null) {
                e.rule.message = 'Amount cannot be empty or 0.'
                return false
            } else if (e.value > transactionDetails["Balance"]) {
                e.rule.message = 'Amount cannot be greater than the balance amount.'
                return false
            }
            return true
        }
    }

    const saveData = () => {
        if (!validationGroup.current.instance().validate().isValid) {
            return
        }
        LoaderService.setData(true);
        let data = JSON.stringify([{
            spanString: "_PaymentDetails",
            data: paymentDetails,
        }])
        apiClient
            .post('/Transactions/RecordPayment', data)
            .then(response => {
                if (response) {
                    try {
                        LoaderService.setData(false);
                        let resp = JSON.parse(response.data);
                        if (resp[0].response) {
                            props.isUpdated();
                        } else {
                            return alert(resp[0].message, 'Warning!');
                        }
                        modalClose();
                    } catch (e) {
                        modalClose();
                        LoaderService.setData(false);
                        return alert(response.data, 'Error!');
                    }
                }
            })
            .catch(err => {
                LoaderService.setData(false);
                console.log(err);
            });
    }

    return (<Modal show={showModal} onHide={modalClose} centered dialogClassName="modal-70w prop-img-slider">
        <Modal.Header closeButton className="border-0 pb-0 modal-header">
            <Modal.Title className="fs-5 fw-bold">
                <span>RECORD TRANSACTION PAYMENT</span>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-3">
            <ValidationGroup ref={validationGroup}>
                <div className="py-2 tabs-border" id="ManageTransaction_DetailsTab">
                    <div className="row tabsContent">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-sm-3 col-md-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Type</Form.Label>
                                        <TextBox ref={typeRef}
                                                 inputAttr={{class: "form-control"}}
                                                 elementAttr={{class: "input-group"}}
                                                 placeholder="Type"
                                                 value={transactionDetails["Type"]}
                                                 readOnly={true}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-sm-3 col-md-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Status</Form.Label>
                                        <TextBox
                                            ref={statusRef}
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Status"
                                            value={transactionDetails["Status"]}
                                            readOnly={true}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-sm-3 col-md-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Id</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="ID"
                                            value={transactionDetails["ID"]}
                                            readOnly={true}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-sm-3 col-md-6">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Property</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Property"
                                            value={transactionDetails["Property"]}
                                            readOnly={true}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-sm-3 col-md-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Date</Form.Label>
                                        <DateBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Date"
                                            value={transactionDetails["Date"]}
                                            readOnly={true}
                                            property="Date"
                                            displayFormat="dd/MM/yyyy"
                                        >
                                        </DateBox>
                                    </Form.Group>
                                </div>
                                <div className="col-sm-3 col-md-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Transaction Reference</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Transaction Reference"
                                            property="Reference"
                                            value={transactionDetails["Reference"]}
                                            readOnly={true}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-sm-3 col-md-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Currency</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Currency"
                                            property="Currency"
                                            value={transactionDetails["Currency"]}
                                            readOnly={true}
                                        >
                                        </TextBox>
                                    </Form.Group>
                                </div>
                                <div className="col-sm-3 col-md-6">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Account</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Account"
                                            searchEnabled={true}
                                            value={transactionDetails["Account"]}
                                            property="Account"
                                            readOnly={true}
                                        >
                                        </TextBox>
                                    </Form.Group>
                                </div>
                                <div className="col-sm-3 col-md-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Amount</Form.Label>
                                        <NumberBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Amount"
                                            property="Amount"
                                            value={transactionDetails["Amount"]}
                                            readOnly={true}
                                            format="#,#00.00"
                                        >
                                        </NumberBox>
                                    </Form.Group>
                                </div>
                                <div className="col-sm-3 col-md-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Tax</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Tax"
                                            searchEnabled={true}
                                            value={transactionDetails["Tax"]}
                                            readOnly={true}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-sm-3 col-md-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Total</Form.Label>
                                        <NumberBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Total"
                                            value={transactionDetails["Total"]}
                                            readOnly={true}
                                            format="#,#00.00"
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-sm-3 col-md-6">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Remarks</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Remarks"
                                            property="Remarks"
                                            value={transactionDetails["Remarks"]}
                                            readOnly={true}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="dx-fieldset-header"
                                     style={{margin: "10px 0", color: "#048992", fontSize: "15px"}}>
                                    Payment Details
                                </div>
                                <div className="col-sm-3 col-md-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Balance Amount</Form.Label>
                                        <NumberBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Balance"
                                            property="Balance"
                                            value={transactionDetails["Balance"]}
                                            format="#,#00.00"
                                            readOnly={true}
                                        >
                                        </NumberBox>
                                    </Form.Group>
                                </div>
                                <div className="col-sm-3 col-md-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Date</Form.Label>
                                        <DateBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Payment Date"
                                            property="Date"
                                            displayFormat="dd/MM/yyyy"
                                            value={paymentDetails["Date"]}
                                            readOnly={false}
                                            onValueChanged={valueChangeHandler}
                                        >
                                            <Validator>
                                                <RequiredRule
                                                    message="Date cannot be empty"></RequiredRule>
                                            </Validator>
                                        </DateBox>
                                    </Form.Group>
                                </div>
                                <div className="col-sm-3 col-md-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Amount</Form.Label>
                                        <NumberBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Amount"
                                            property="Amount"
                                            value={paymentDetails["Amount"]}
                                            format="#,#00.00"
                                            readOnly={false}
                                            onValueChanged={valueChangeHandler}
                                        >
                                            <Validator>
                                                <CustomRule
                                                    property="Amount"
                                                    validationCallback={customValidation}
                                                    reevaluate={true}
                                                />
                                                <RequiredRule
                                                    message="Amount cannot be empty"></RequiredRule>
                                            </Validator>
                                        </NumberBox>
                                    </Form.Group>
                                </div>
                                <div className="col-sm-3 col-md-6">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Account</Form.Label>
                                        <SelectBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Account"
                                            property="Account"
                                            value={paymentDetails["Account"]}
                                            readOnly={false}
                                            displayExpr="Name"
                                            valueExpr="externalId"
                                            dataSource={accounts}
                                            onValueChanged={valueChangeHandler}
                                        >
                                            <Validator>
                                                <RequiredRule
                                                    message="Account cannot be empty"></RequiredRule>
                                            </Validator>
                                        </SelectBox>
                                    </Form.Group>
                                </div>
                                <div className="col-sm-6 col-md-12">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Remark</Form.Label>
                                        <TextBox
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "input-group"}}
                                            placeholder="Remark"
                                            property="Remark"
                                            value={paymentDetails["Remark"]}
                                            readOnly={false}
                                            onValueChanged={valueChangeHandler}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ValidationGroup>
        </Modal.Body>
        <Modal.Footer className="pt-2">
            <>
                <Button variant="outline-primary" className="flex-shrink-0 me-3"
                        onClick={saveData}>
                    Save
                </Button>
                <Button variant="outline-primary" className="flex-shrink-0 me-3"
                        onClick={modalClose}>
                    Cancel
                </Button>
            </>
        </Modal.Footer>
    </Modal>)
}

export default RecordPayment;