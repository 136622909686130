import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import React, {useState} from "react";
import {connect} from 'react-redux'
import Header from "../components/Header";
import Menu from "../components/Menu";
import {useEffect} from "react";
import Login from "../pages/login";
import LoaderService from "../RetroAssets.System/LoaderService";
import {LoadPanel} from 'devextreme-react/load-panel'


const mapStateToProps = ({settings, user}) => ({
    dispatch: settings.dispatch,
    user: user
})

const Layout = (props) => {
    const {dispatch, user} = props;
    const location = useLocation();
    const navigate = useNavigate();
    const isUserAuthorized = user.authorized;
    const isLoading = user.loading;

    const position = {of: '#root'}
    const [showLoadIndicator, setLoadIndicator] = useState(false)

    LoaderService.getData().subscribe(message => {
        if (message === undefined) return
        return;
        // setLoadIndicator(message.value)
    })

    useEffect(() => {
        changeSetting('navigate', navigate);
    }, [])

    const changeSetting = (setting, value) => {
        dispatch({
            type: 'settings/CHANGE_SETTING',
            payload: {
                setting,
                value,
            },
        });
    };

    const LayoutConfiguration = () => {
        if (isLoading) {
            return <></>
        }
        if (!isUserAuthorized) {
            return <Login/>
        }
        return (
            <div className="d-flex flex-column wrapper">
                <LoadPanel
                    shadingColor="rgba(0,0,0,0.4)"
                    position={position}
                    visible={showLoadIndicator}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                    hideOnOutsideClick={false}
                />
                {/* Common Top Header */}
                <Header/>
                {/* Main content body */}
                <div className={`content-wrapper ${(location.pathname) === "/" ? "pb-0" : ""}`}><Outlet/></div>
                {/* Common Bottom Navigation Bar */}
                <Menu/>
            </div>
        )
    }
    return (<>
        {LayoutConfiguration()}
    </>)

}

export default connect(mapStateToProps)(Layout)