import React, {useState, useEffect, useRef, useImperativeHandle} from 'react';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import {TextBox} from "devextreme-react";
import TransactionsComponent from "../../RetroAssets.System/Components/TransactionsComponent";
import LoaderService from "../../RetroAssets.System/LoaderService";
import apiClient from "../../services/axios";
import {alert} from 'devextreme/ui/dialog';
import cl from "../../RetroAssets.System/Utilities";

const InnerComponent = React.forwardRef((props, gridInstance) => {
    const {mode, active} = props
    const propertiesSearchBox = useRef(null);
    const transactionsRef = useRef(null);

    const [gridData, setGridData] = useState([]);
    const [gridColumns, setGridColumns] = useState([]);
    const [gridCustomization, setGridCustomization] = useState({});
    const spanString = 'TransactionListing'
    const [tabCounts, setTabCounts] = useState([])
    const [tempMode, setTempMode] = useState('')
    const [gridHeight, setGridHeight] = useState(10)

    useEffect(() => {
        setGridHeight(cl.setDataGridHeight())
    }, [])

    useImperativeHandle(gridInstance, () => {
        return {
            getData(params) {
                getData(params);
                setTempMode(params)
            }
        };
    }, []);

    function getData(modeString) {
        let params = new URLSearchParams({
            mode: modeString
        })
        apiClient
            .get('/Transactions/GetAllTransactions?' + params)
            .then(response => {
                try {
                    let data = JSON.parse(response.data)
                    let dt = data[0]['Data']
                    generateTabCounts(dt)
                    setGridData(dt)
                    let settings = data[1]["Settings"]
                    setGridColumns(settings[0][spanString][0]['Columns'])
                    setGridCustomization(settings[0][spanString][3]['GridCustomization'][0])
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    const searchPropertyByText = e => {
        if (!e.event) return
        transactionsRef.current?.instance().searchByText(e.value ? e.value : null)
    }

    const generateTabCounts = data => {
        let tempTabs = []
        tempTabs.push({Name: "ALL", externalId: "ALL", Count: data.length})
        tempTabs.push({
            Name: "Income",
            externalId: 'Income',
            Count: data.filter(x => x['Income']).length
        })
        tempTabs.push({
            Name: "Expenses",
            externalId: 'Expenses',
            Count: data.filter(x => !x['Income']).length
        })
        setTabCounts(tempTabs)
    }

    const tabChanged = e => {
        if (e === "Income")
            transactionsRef.current?.instance().filter(["GroupName", "=", "INCOME"])
        else if (e === "Expenses")
            transactionsRef.current?.instance().filter(["GroupName", "=", "EXPENSE"])
        else
            transactionsRef.current?.instance().clearFilter()
    }

    return (
        <div className="main-wrapper p-3" ref={gridInstance}>
            <Card className="p-3 border-0 dashboard-border">
                <div
                    className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-0 mb-md-4">
                    <ul className="nav nav-tabs fs-6 nav-tab tab-sm inner-tab mb-3 mb-xl-0">
                        {tabCounts.map((item, index) => {
                            return (<li key={item["externalId"]} className="nav-item" onClick={() => {
                                tabChanged(item["externalId"])
                            }}>
                                <a className={index === 0 ? "nav-link active" : "nav-link"} data-bs-toggle="tab"
                                   eventKey={item["externalId"]}
                                   href={'#' + item["externalId"]}>{item["Name"]}<span
                                    className="tab-count"
                                >{item["Count"]}</span></a>
                            </li>)
                        })}
                    </ul>
                    <div className="d-flex justify-md-content-end flex-wrap flex-md-nowrap">
                        <InputGroup className="me-3 search-input">
                            <TextBox placeholder="Search Transactions..."
                                     ref={propertiesSearchBox}
                                     mode="search"
                                     inputAttr={{class: "form-control"}}
                                     elementAttr={{class: "search-input input-group"}}
                                     width="100%"
                                     onValueChanged={searchPropertyByText}
                            ></TextBox>
                        </InputGroup>
                        <div className="d-flex actions-btn-group mb-3 mb-md-0">
                            {/*<Button variant="outline-light" className="action-btn px-2"><Image src="svg/refresh.svg"*/}
                            {/*                                                                   alt="refresh" width="20"*/}
                            {/*                                                                   height="20"*/}
                            {/*                                                                   onClick={() => getData(tempMode)}/></Button>*/}
                            {/*<Button variant="outline-light" className="action-btn px-2"><Image src="svg/columns.svg"*/}
                            {/*                                                                   alt="columns" width="20"*/}
                            {/*                                                                   height="20"/></Button>*/}
                        </div>
                    </div>
                </div>
                {/* Tabs body */}
                <div className="tab-content pt-1">
                    <TransactionsComponent
                        key="7E17EC1C-C9DE-4694-8648-6CB03B21B5C2"
                        ref={transactionsRef}
                        data={gridData}
                        columns={gridColumns}
                        gridCustomization={gridCustomization}
                        income={false}
                        expense={false}
                        height={gridHeight}
                        isUpdated={() => {
                            getData(tempMode)
                        }}
                    ></TransactionsComponent>
                </div>
            </Card>
        </div>
    );
})

export default InnerComponent;