import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";
import {connect} from "react-redux";
import React, {useCallback, useEffect, useRef, useState} from "react";
import LoaderService from "../../../RetroAssets.System/LoaderService";
import {alert, confirm} from "devextreme/ui/dialog";
import apiClient from "../../../services/axios";
import {useNavigate} from "react-router-dom";
import cl from "../../../RetroAssets.System/Utilities";
import {TextBox} from "devextreme-react";
import ContractsComponent from "../../../RetroAssets.System/Components/ContractsComponent";
import ManageTenancyContractDetails from "./ManageTenancyContractDetails";

const mapStateToProps = ({settings}) => ({
    dispatch: settings.dispatch,
    SettingsForTenancyContract: settings.SettingsForTenancyContract
        ? JSON.parse(settings.SettingsForTenancyContract)
        : null,
});

const TenancyContract = (props) => {
    const navigate = useNavigate();
    const manageContractDetailsReference = useRef(null);
    let contractSpanString = "Property_TenancyContracts";
    const [selectedContract, setSelectedContract] = useState([]);

    const gridInstance = useRef(null);
    const contractsSearchRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const [gridColumns, setGridColumns] = useState([]);
    const [gridCustomization, setGridCustomization] = useState({});

    const [viewContractList, setViewContractList] = useState(true);
    const [manageContractSettings, setManageContractSettings] = useState({
        externalId: "",
        createNew: false,
        defaultCurrency: '',
        defaultTax: ''
    });

    useEffect(() => {
        if (!props.SettingsForTenancyContract) {
            navigate(-1);
            return;
        }
        getData(false);
    }, []);

    function getData(showToast) {
        LoaderService.setData(true);
        let params = new URLSearchParams({
            externalId: props.SettingsForTenancyContract["externalId"], //"357458b0-e848-45f0-b83b-739aba9fbd3a"
        });
        apiClient
            .get("/Property/GetAllPropertyContracts?" + params)
            .then((response) => {
                try {
                    let data = JSON.parse(response.data);
                    setGridData(data[0]["Data"]);
                    let settings = data[1]["Settings"];
                    setSelectedContract(props.SettingsForTenancyContract);
                    setGridColumns(settings[0][contractSpanString][0]["Columns"]);
                    setGridCustomization(
                        settings[0][contractSpanString][3]["GridCustomization"][0]
                    );
                    if (showToast) cl.showSuccessToast();
                    LoaderService.setData(false);
                } catch (e) {
                    LoaderService.setData(false);
                    return alert(response.data, "Error!");
                }
            })
            .catch((err) => {
                LoaderService.setData(false);
                console.log(err);
                return alert(err, "Error!");
            });
    }

    const generateContractStatus = (e, capitalText = false) => {
        if (!e) return;
        if (!e["Active"]) {
            //TERMINATED
            return (
                <label
                    key={e.key}
                    className={
                        capitalText
                            ? "text-capitalize form-check-label fs-4 fw-bold "
                            : "form-check-label"
                    }
                    htmlFor="ps-na"
                >
                    <span className="status-color"></span>
                    {e["ContractStatus"]}
                </label>
            );
        } else if (e["Terminated"] && e["Active"]) {
            //PENDING TERMINATION DETAILS
            return (
                <label
                    key={e.key}
                    className={
                        capitalText
                            ? "text-capitalize form-check-label fs-4 fw-bold "
                            : "form-check-label"
                    }
                    htmlFor="ps-unoccupied"
                >
                    <span className="status-color na"></span>
                    {e["ContractStatus"]}
                </label>
            );
        } else if (e["Overdue"]) {
            //OVERDUE
            return (
                <label
                    key={e.key}
                    className={
                        capitalText
                            ? "text-capitalize form-check-label fs-4 fw-bold "
                            : "form-check-label"
                    }
                    htmlFor="ps-overdue"
                >
                    <span className="status-color overdue"></span>
                    {e["ContractStatus"]}
                </label>
            );
        } else if (e["Due"]) {
            ///DUE
            return (
                <label
                    key={e.key}
                    className={
                        capitalText
                            ? "text-capitalize form-check-label fs-4 fw-bold "
                            : "form-check-label"
                    }
                    htmlFor="ps-occupied"
                >
                    <span className="status-color na"></span>
                    {e["ContractStatus"]}
                </label>
            );
        } else {
            return (
                <label
                    key={e.key}
                    className={
                        capitalText
                            ? "text-capitalize form-check-label fs-4 fw-bold "
                            : "form-check-label"
                    }
                    htmlFor="ps-occupied"
                >
                    <span className="status-color occupied"></span>
                    {e["ContractStatus"]}
                </label>
            );
        }
    };

    const generatePropertyStatus = (e) => {
        if (e["PurchasePaymentPlan"]) {
            //Payment Plan
            return (
                <label
                    key={props.key}
                    className="text-capitalize form-check-label fs-4 fw-bold"
                    htmlFor="ps-na"
                >
                    <span className="status-color na"></span>
                    {e["Status"]}
                </label>
            );
        } else if (!e["Available"]) {
            //Not Available
            return (
                <label
                    key={props.key}
                    className="text-capitalize form-check-label fs-4 fw-bold"
                    htmlFor="ps-unoccupied"
                >
                    <span className="status-color unoccupied"></span>
                    {e["Status"]}
                </label>
            );
        } else if (!e["Occupied"]) {
            //Unoccupied
            return (
                <label
                    key={props.key}
                    className="text-capitalize form-check-label fs-4 fw-bold"
                    htmlFor="ps-overdue"
                >
                    <span className="status-color overdue"></span>
                    {e["Status"]}
                </label>
            );
        } else if (e["Occupied"]) {
            //Occupied
            return (
                <label
                    key={props.key}
                    className="text-capitalize form-check-label fs-4 fw-bold"
                    htmlFor="ps-occupied"
                >
                    <span className="status-color occupied"></span>
                    {e["Status"]}
                </label>
            );
        }
    };

    const backToContractListing = () => {
        navigate(-1);
    }

    const generateComponentView = () => {
        if (viewContractList)
            return (
                <div className="rounded p-3 border border-secondary mb-3">
                    <div className="card-body p-0">
                        <div
                            className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-2">
                            <div className="d-sm-flex align-items-center justify-content-between">
                                <Button
                                    variant="outline-primary"
                                    className="flex-shrink-0 me-3"
                                    onClick={backToContractListing}
                                >
                                    <Image src="/svg/chevron-left-green.svg"
                                           alt="refresh" width="20"
                                           height="20"/>
                                    Back to Previous
                                </Button>
                            </div>
                            <div className="d-flex">
                                <Button
                                    variant="outline-primary"
                                    className="flex-shrink-0 me-3"
                                    onClick={addNewContract}
                                >
                                    Add
                                </Button>
                                <Button
                                    variant="outline-primary"
                                    className="flex-shrink-0 me-3"
                                    onClick={clearFilter}
                                >
                                    Clear Filter
                                </Button>
                                <InputGroup className="me-3 search-input">
                                    <TextBox
                                        ref={contractsSearchRef}
                                        placeholder="Search Transactions..."
                                        mode="search"
                                        inputAttr={{class: "form-control temp"}}
                                        elementAttr={{class: "search-input input-group"}}
                                        width="100%"
                                        onValueChanged={searchContractsByText}
                                    ></TextBox>
                                </InputGroup>
                            </div>
                        </div>
                        <ContractsComponent
                            showDashboardOptions={false}
                            showDataChangeOptions={true}
                            dashboardMode={false}
                            ref={gridInstance}
                            data={gridData}
                            columns={gridColumns}
                            gridCustomization={gridCustomization}
                            showContractDetails={(e) => {
                                setManageContractSettings({
                                    ...manageContractSettings,
                                    externalId: e,
                                    createNew: false,
                                    defaultCurrency: '',
                                    defaultTax: ''
                                });
                                setViewContractList(false);
                            }}
                            height={500}
                            isUpdated={() => {
                                return getData(true)
                            }}
                        ></ContractsComponent>
                    </div>
                </div>
            );
        else
            return (
                <ManageTenancyContractDetails
                    ref={manageContractDetailsReference}
                    externalId={manageContractSettings.externalId}
                    propertyExternalId={props.SettingsForTenancyContract["externalId"]}
                    createNew={manageContractSettings.createNew}
                    defaultCurrency={manageContractSettings.defaultCurrency}
                    defaultTax={manageContractSettings.defaultTax}
                    showMainPage={() => setViewContractList(true)}
                    rowData={() => {
                        let index = gridData.findIndex(x => x["externalId"] === manageContractSettings.externalId)
                        if (index > -1) {
                            return gridData[index]
                        }
                        return {}
                    }}
                ></ManageTenancyContractDetails>
            );
    };

    //#region Data Grid Functions

    const clearFilter = () => {
        gridInstance.current?.instance().clearFilter();
        gridInstance.current?.instance().searchByText("");
        contractsSearchRef.current?.instance().option("value", null);
    };

    const searchContractsByText = (e) => {
        if (!e.event) return;
        gridInstance.current?.instance().searchByText(e.value ? e.value : null);
    };

    const addNewContract = () => {
        if (!props.SettingsForTenancyContract["Available"])
            return alert(
                "Current property is not available for renting.",
                "Warning!"
            );
        setManageContractSettings({
            ...manageContractSettings,
            externalId: '',
            createNew: true,
            defaultCurrency: '',
            defaultTax: '',
        });
        setViewContractList(false);
    }

    //#endregion

    return (
        <>

            <div
                className="d-flex flex-wrap flex-lg-nowrap justify-content-between align-items-center px-3 pt-3 bg-white border-bottom">
                <div className="d-flex align-items-center fs-5 mb-3">
          <span className="text-secondary cursor-pointer">
            Property Listings
          </span>
                    <Image
                        src="/svg/chevron-left.svg"
                        alt="chevron-left"
                        width="20"
                        height="20"
                        className="mx-1"
                    />
                    <h2 className="fs-5 fw-medium mb-0 flex-shrink-0 me-4">
                        Property Contracts
                    </h2>
                </div>
                <div className="d-flex flex-wrap flex-xl-nowrap mb-3">
                    <Button variant="outline-primary" className="px-3">
                        Export All Data
                    </Button>
                </div>
            </div>
            <div className="main-wrapper p-3">
                <Card className="p-3 border-0">
                    <div className="row mb-3">
                        <div className="col-sm-12 col-lg-4 mb-3 mb-xl-0">
                            <Card className="card-light p-3 border-0 text-center h-100 justify-content-center">
                                <div className="fs-4 fw-bold mb-1">
                                    {selectedContract["Name"]}
                                </div>
                                <div className="text-secondary text-uppercase">
                                    Property name
                                </div>
                            </Card>
                        </div>
                        <div className="col-md-12 col-lg-8">
                            <div className="row">
                                <div className="col-6 col-md-4 col-lg-4 col-xl col-md mb-3 mb-xl-0">
                                    <Card className="card-light p-3 border-0 text-center h-100">
                                        <div className="fs-4 fw-bold mb-1">
                                            {gridData.length > 0
                                                ? gridData[0]["DisplayStartDate"]
                                                : "N/A"}
                                        </div>
                                        <div className="text-secondary text-uppercase">
                                            Start date
                                        </div>
                                    </Card>
                                </div>
                                <div className="col-6 col-md-4 col-lg-4 col-xl mb-3 mb-xl-0">
                                    <Card className="card-light p-3 border-0 text-center h-100">
                                        <div className="fs-4 fw-bold mb-1 text-capitalize">
                                            {gridData.length > 0
                                                ? gridData[0]["DisplayEndDate"]
                                                : "N/A"}
                                        </div>
                                        <div className="text-secondary text-uppercase">
                                            End date
                                        </div>
                                    </Card>
                                </div>
                                <div className="col-6 col-md-4 col-lg-4 col-xl mb-3 mb-xl-0">
                                    <Card className="card-light p-3 border-0 h-100">
                    <span className="fs-7 mb-1 align-self-center">
                      {generatePropertyStatus(selectedContract)}
                    </span>
                                        <div className="text-secondary text-uppercase align-self-center">
                                            Property Status
                                        </div>
                                    </Card>
                                </div>
                                <div className="col-6 col-md-4 col-lg-4 col-xl mb-3 mb-xl-0">
                                    <Card className="card-light p-3 border-0 text-center h-100">
                                        <div className="fs-4 fw-bold mb-1 text-capitalize">
                                            {selectedContract["PropertyType"]}
                                        </div>
                                        <div className="text-secondary text-uppercase">
                                            Property type
                                        </div>
                                    </Card>
                                </div>
                                <div className="col-6 col-md-4 col-lg-4 col-xl mb-3 mb-xl-0">
                                    <Card className="card-light p-3 border-0 h-100">
                    <span className="fs-7 mb-1 align-self-center">
                      {generateContractStatus(gridData[0], true)}
                    </span>
                                        <div className="text-secondary text-uppercase align-self-center">
                                            Contract Expiry
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                    {generateComponentView()}
                </Card>
            </div>
        </>
    );
};

export default connect(mapStateToProps)(TenancyContract);
