import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import {DataGrid} from "devextreme-react/data-grid";
import {Popover, Template, TextArea} from "devextreme-react";
import {MoreOptionsButton} from "../MoreOptionsButton";
import cl from "../Utilities";
import apiClient from "../../services/axios";
import LoaderService from "../LoaderService";
import {alert, confirm} from "devextreme/ui/dialog";
import TenancyContractCancellation from "../../RetroAssets/Property/TenancyContract/TenancyContractCancellation";
import ManageTenancyContractFileAttachments from "../../RetroAssets/Popups/ManageTenancyContractFileAttachments";

const ContractsComponent = React.forwardRef((props, componentReference) => {
    const popoverRef = useRef(null);
    const gridInstance = useRef(null);

    const {
        data,
        columns,
        gridCustomization,
        height,
        dashboardMode,
        showDashboardOptions = false,
        showDataChangeOptions = false,
    } = props;
    const [tenancyContractData, setTenancyContractData] = useState([]);
    const [tenancyContractColumns, setTenancyContractColumns] = useState([]);
    const [
        tenancyContractGridCustomization,
        setTenancyContractGridCustomization,
    ] = useState({});
    const [isDoubleClick, setIsDoubleClick] = useState(false);
    const [cancelContractDetails, setCancelContractDetails] = useState({
        show: false,
        externalId: "",
    });
    const [manageFileAttachmentDetails, setManageFileAttachmentDetails] = useState({
        show: false,
        externalId: "",
        instantMode: false,
        readOnly: false
    })


    useEffect(() => {
        setTenancyContractData(data);
        setTenancyContractGridCustomization(gridCustomization);
        let cols = columns;
        let index = cols.findIndex((x) => x.dataField === "Remark");
        if (index > -1) {
            cols[index]["editCellTemplate"] = "remarksTemplate";
        }
        setTenancyContractColumns(cols);
    }, [data, columns, gridCustomization]);

    useImperativeHandle(
        componentReference,
        () => {
            return {
                addNewProperty() {
                    return null;
                },
                instance() {
                    return gridInstance.current.instance();
                },
            };
        },
        []
    );

    const rowClickEvent = (e) => {
        popoverRef.current.instance().option("target", e.rowElement);
        popoverRef.current.instance().show();
    };

    const RenderPopoverTemplate = () => {
        const changeColor = (colorCode) => {
            let baseKey = gridInstance.current.instance().getSelectedRowKeys()[0];
            let selectedRowId = dashboardMode
                ? gridInstance.current.instance().getSelectedRowsData()[0][
                    "propertyExternalId"
                    ]
                : gridInstance.current.instance().getSelectedRowKeys()[0];

            let params = new URLSearchParams({
                objectName: dashboardMode ? "Property" : "TenancyContract",
                externalId: selectedRowId,
                color: colorCode,
                remove: colorCode === null,
            });
            apiClient
                .get("/Shared/UpdateObjectColor?" + params)
                .then((response) => {
                    try {
                        LoaderService.setData(false);
                        let resp = JSON.parse(response.data);
                        if (resp[0].response) {
                            debugger;
                            let index = tenancyContractData.findIndex(
                                (x) => x.externalId === baseKey
                            );
                            if (index > -1) {
                                tenancyContractData[index][
                                    dashboardMode ? "Color" : "TenancyContractColor"
                                    ] = colorCode;
                                setTenancyContractData(tenancyContractData);
                                gridInstance.current.instance().refresh();
                                gridInstance.current.instance().repaint();
                            }
                        } else {
                            return alert(resp[0].message, "Warning!");
                        }
                        LoaderService.setData(false);
                    } catch (e) {
                        LoaderService.setData(false);
                        return alert(response.data, "Error!");
                    }
                })
                .catch((err) => {
                    LoaderService.setData(false);
                    console.log(err);
                    return alert(err, "Error!");
                });
        };
        return (
            <>
        <span
            className="dot dot-color1"
            onClick={() => changeColor("#D2E2FF")}
        ></span>
                <span
                    className="dot dot-color2"
                    onClick={() => changeColor("#FEE8B3")}
                ></span>
                <span
                    className="dot dot-color3"
                    onClick={() => changeColor("#B1E3CE")}
                ></span>
                <span
                    className="dot dot-color4"
                    onClick={() => changeColor("#D4D4D4")}
                ></span>
                <span
                    className="dot dot-color5"
                    onClick={() => changeColor("#FFCFAF")}
                ></span>
                <span
                    className="dot dot-color6"
                    onClick={() => changeColor("#EDD7FF")}
                ></span>
                <span
                    className="dot dot-color7"
                    onClick={() => changeColor("#FFA7A7")}
                ></span>
                <span className="dot dot-none" onClick={() => changeColor(null)}>
          {" "}
        </span>
            </>
        );
    };

    const cellPrepared = (e) => {
        cl.setObjectColor(e, dashboardMode ? null : "TenancyContractColor");
    };

    const contextMenu = (e) => {
        if (showDataChangeOptions) {
            if (!e.row) return;
            if (e.row.rowType !== "data") return;
            e.items = [];
            e.component.selectRows(e.row.key);

            e.items.push({
                text: "View | Manage Contract",
                onClick: function () {
                    props.showContractDetails(e.row.key);
                },
            });

            e.items.push({
                beginGroup: true,
                text: "Terminate Contract",
                onClick: function () {
                    return checkCanTerminateContract(e.row.data)
                }
            })

            e.items.push({
                text: "Manage Termination Details",
                onClick: function () {
                    return manageTerminationDetails(e.row.data)
                }
            })

            e.items.push({
                beginGroup: true,
                text: "Manage File Attachments",
                onClick: function () {
                    setManageFileAttachmentDetails({
                        ...manageFileAttachmentDetails,
                        externalId: e.row.key,
                        instantMode: true,
                        show: true,
                        readOnly: false
                    })
                }
            })
        }
    };

    const cellDoubleClick = (e) => {
        if (e.column.dataField === "Remark") {
            setIsDoubleClick(true);
            e.component.editCell(e.rowIndex, e.columnIndex);
        }
    };

    const editingStart = (e) => {
        if (e.column.dataField === "Remark") {
            if (!isDoubleClick) {
                e.component.selectRows(e.key);
                e.cancel = true;
            }
            if (isDoubleClick) {
                setIsDoubleClick(false);
            }
        }
    };

    const checkCanTerminateContract = (e) => {
        if (e["Terminated"])
            return alert(
                "Selected contract has already been marked as terminated.",
                "Warning!"
            );

        if (!e["Active"])
            return alert(
                "Selected contract has already been terminated.",
                "Warning!"
            );

        if (e["NotPaid"]) {
            return confirm(
                "One or more transactions for this contract is left unpaid.<br />Are you sure you wish to mark contract as terminated?",
                "Warning!"
            ).then((resp) => {
                if (resp) return markContractAsTerminated();
            });
        }
        confirm(
            "Are you sure you wish to mark the contract as terminated?",
            "Warning!"
        ).then((resp) => {
            if (resp) return markContractAsTerminated(e.externalId);
        });
    }

    const markContractAsTerminated = (e) => {
        LoaderService.setData(true);
        let params = new URLSearchParams({
            externalId: e
        })
        apiClient
            .get("/Property/MarkTenancyContractAsTerminated?" + params)
            .then((response) => {
                try {
                    LoaderService.setData(false);
                    let resp = JSON.parse(response.data);
                    if (resp[0].response) {
                        props.isUpdated()
                    } else {
                        return alert(resp[0].message, "Warning!");
                    }
                } catch (e) {
                    LoaderService.setData(false);
                    return alert(response.data, "Error!");
                }
            })
            .catch((err) => {
                LoaderService.setData(false);
                console.log(err);
                return alert(err, "Error!");
            });
    };

    const manageTerminationDetails = (e) => {
        if (e["NotPaid"] && e["Active"]) {
            return confirm(
                "One or more transactions for this contract is left unpaid.<br />Are you sure you wish to mark contract as terminated?",
                "Warning!"
            ).then((resp) => {
                if (resp) {
                    setCancelContractDetails({
                        ...cancelContractDetails,
                        show: true,
                        externalId: e["externalId"],
                    });
                }
            });
        }

        setCancelContractDetails({
            ...cancelContractDetails,
            show: true,
            externalId: e["externalId"],
        });
    };

    return (
        <>
            <ManageTenancyContractFileAttachments
                show={manageFileAttachmentDetails.show}
                externalId={manageFileAttachmentDetails.externalId}
                instantMode={manageFileAttachmentDetails.instantMode}
                readOnly={manageFileAttachmentDetails.readOnly}
                handleClose={() => {
                    setManageFileAttachmentDetails({
                        ...manageFileAttachmentDetails,
                        show: false,
                    });
                }}
                isInstantUpdated={() => {
                    props.isUpdated()
                }}
                isUpdated={(gridData, spanString, editedData, deletedItems, attachmentButtonVal) => {
                    return
                }}
            ></ManageTenancyContractFileAttachments>
            <TenancyContractCancellation
                show={cancelContractDetails.show}
                externalId={cancelContractDetails.externalId}
                handleClose={() => {
                    setCancelContractDetails({
                        ...cancelContractDetails,
                        show: false,
                    });
                }}
                isUpdated={() => {
                    props.isUpdated()
                }}
            ></TenancyContractCancellation>
            <Popover
                ref={popoverRef}
                showEvent="dxclick"
                hideOnOutsideClick={true}
                width={180}
                contentRender={RenderPopoverTemplate}
            ></Popover>
            <DataGrid
                ref={gridInstance}
                dataSource={tenancyContractData}
                onRowClick={rowClickEvent}
                onCellPrepared={cellPrepared}
                onEditingStart={editingStart}
                onCellDblClick={cellDoubleClick}
                onContextMenuPreparing={contextMenu}
                {...tenancyContractGridCustomization}
                keyExpr="externalId"
                height={height}
            >
                {tenancyContractColumns.map((column, i) =>
                    cl.generateReactColumnsFromJson(column)
                )}
                {tenancyContractColumns.map((column, i) =>
                    cl.generateActionButtonColumn(column)
                )}
                <Template name="myTemplate" render={MoreOptionsButton}/>
                <Template name="propertyNameTemplate" render={PropertyNameColumn}/>
                <Template name="statusTemplate" render={StatusColumn}/>
                <Template name="remarksTemplate" render={RemarksColumn}/>
            </DataGrid>
        </>
    );
});

export default ContractsComponent;

const PropertyNameColumn = (props) => {
    return (
        <div key={props.key}>
            <div className="col-12">
                <b>{props.data["Name"]}</b>
            </div>
            <div className="col-12">{props.data["Unit"]}</div>
        </div>
    );
};

const StatusColumn = (props) => {
    if (!props.data["Available"]) {
        //N/A
        return (
            <label
                key={props.key}
                className="form-check-label"
                title={"Property not available for renting"}
            >
                <span className="status-color"></span>
                {props.data["ContractStatus"]}
            </label>
        );
    } else if (!props.data["Active"]) {
        //TERMINATED
        return (
            <label key={props.key} className="form-check-label" title={"Terminated"}>
                <span className="status-color"></span>
                {props.data["ContractStatus"]}
            </label>
        );
    } else if (props.data["Terminated"] && props.data["Active"]) {
        //PENDING TERMINATION DETAILS
        return (
            <label
                key={props.key}
                className="form-check-label"
                title={"Contract terminated but pending termination details"}
            >
                <span className="status-color na"></span>
                {props.data["ContractStatus"]}
            </label>
        );
    } else if (props.data["Overdue"]) {
        //OVERDUE
        return (
            <label key={props.key} className="form-check-label" title="Overdue">
                <span className="status-color overdue"></span>
                {props.data["ContractStatus"]}
            </label>
        );
    } else if (props.data["Due"]) {
        //DUE
        return (
            <label key={props.key} className="form-check-label" title="Due">
                <span className="status-color na"></span>
                {props.data["ContractStatus"]}
            </label>
        );
    } else {
        //OCCUPIED
        return (
            <label key={props.key} className="form-check-label" title="Occuped">
                <span className="status-color occupied"></span>
                {props.data["ContractStatus"]}
            </label>
        );
    }
};

const RemarksColumn = (props) => {
    const onValueChanged = useCallback(
        (e) => {
            if (!e.event) return;
            LoaderService.setData(true);
            let params = new URLSearchParams({
                externalId: props.data.externalId,
                remark: e.value,
            });
            apiClient
                .get("/Property/UpdateTenancyContractRemark?" + params)
                .then((response) => {
                    try {
                        LoaderService.setData(false);
                        let resp = JSON.parse(response.data);
                        if (resp[0].response) {
                            cl.showSuccessToast();
                            props.setValue(e.value);
                            props.component.refresh();
                        } else {
                            props.setValue(e.previousValue);
                            props.component.refresh();
                            return alert(resp[0].message, "Warning!");
                        }
                    } catch (e) {
                        LoaderService.setData(false);
                        return alert(response.data, "Error!");
                    }
                })
                .catch((err) => {
                    LoaderService.setData(false);
                    console.log(err);
                    return alert(err, "Error!");
                });
        },
        [props]
    );

    return <TextArea value={props.value} onValueChanged={onValueChanged}/>;
};
