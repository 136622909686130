import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.greenmist.compact.css'
import React from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import ReactDOM from 'react-dom'
import config from "devextreme/core/config";

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/global.scss';
import Layout from "../src/layouts/layout";

import Login from "./pages/login";
import Dashboard from "./RetroAssets/Dashboard";
import Transactions from "./RetroAssets/Transactions";
import Property from "./RetroAssets/Property";
import PurchasePayment from "./RetroAssets/Property/PurchasePayment";
import PropertyExpenses from "./RetroAssets/Property/propertyExpenses";
import TenancyContract from "./RetroAssets/Property/TenancyContract";
import Configuration from "./pages/Configuration";
import Loans from "./pages/Loans";


import {Provider} from 'react-redux'
import {routerMiddleware} from "connected-react-router";
// import {createHashHistory} from 'history'
import {createBrowserHistory} from "history";
import {createStore, applyMiddleware, compose} from 'redux'
import createSagaMiddleware from 'redux-saga'
import reducers from './services/redux/reducers'
import sagas from './services/redux/sagas'
import * as serviceWorker from './serviceWorker'
import './global.css'
import ManageLookups from "./RetroAssets/Configuration/ManageLookups";
import ManageAppUsers from "./RetroAssets/Configuration/ManageAppUsers";
import ManageBankAccounts from "./RetroAssets/Configuration/ManageBankAccounts";
import ManageOwners from "./RetroAssets/Configuration/ManageOwners";
import ManageVendors from "./RetroAssets/Configuration/ManageVendors";

const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]
// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger)
// }
config({
    licenseKey: 'ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogImIwZWY5NzhhLTM5Y2EtNDY3Mi1hOGRkLThjYTA2MjlmM2NmMyIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjQyCn0=.e7kqnKxd+UHWfe2a0IpUGSiHCOpRNNbcXtb1Ly7MU3eSkSO/aJd26xfe0RrOkBNHm5Fuz5rfU/3KcGxJUxYHbgEiYGJNhaWuYgOl+GQd2E0bPrA3leQ+jft7ik9AFAmWsgXVDQ=='
});

const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<Login/>}/>
                    <Route path="dashboard" element={<Dashboard/>}/>
                    <Route path="transactions" element={<Transactions/>}/>
                    <Route path="property" element={<Property/>}/>
                    <Route path="property/purchase-payment" element={<PurchasePayment/>}/>
                    <Route path="property/property-expenses" element={<PropertyExpenses/>}/>
                    <Route path="property/tenancy-contract-details" element={<TenancyContract/>}/>
                    <Route path="configuration" element={<Configuration/>}/>
                    <Route path="configuration/manage-lookups" element={<ManageLookups/>}/>
                    <Route path="configuration/manage-app-users" element={<ManageAppUsers/>}/>
                    <Route path="configuration/manage-bank-accounts" element={<ManageBankAccounts/>}/>
                    <Route path="configuration/manage-owners" element={<ManageOwners/>}/>
                    <Route path="configuration/manage-vendors-agents" element={<ManageVendors/>}/>
                    <Route path="loans" element={<Loans/>}/>
                </Route>
            </Routes>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root'),
)

serviceWorker.unregister()

export {store, history}
