import React, {useEffect, useState, useCallback, useRef, useImperativeHandle} from "react";
import {DataGrid, Switch, Template, TextArea, Popover} from "devextreme-react";
import {Column, MasterDetail} from "devextreme-react/data-grid";
import {ContextMenuTemplate, MoreOptionsButton} from "../MoreOptionsButton";
import cl from "../Utilities";
import apiClient from "../../services/axios";
import LoaderService from "../LoaderService";
import {alert, confirm} from "devextreme/ui/dialog";
import ManageTransactionDetails from "../../RetroAssets/Popups/ManageTransactionDetails";
import RecordPayment from "../../RetroAssets/Popups/RecordPayment";
import CancelTransaction from "../../RetroAssets/Popups/CancelTransaction";

const TransactionsComponent = React.forwardRef((props, componentReference) => {
    const popoverRef = useRef(null)

    const {
        data,
        columns,
        gridCustomization,
        height,
        income,
        expense,
        tenancyContract = false,
        purchasePayment = false,
        propertyExpenses = false,
        summaryObject = {}
    } = props
    const gridInstance = useRef(null)
    const [transactionData, setTransactionData] = useState([])
    const [transactionColumns, setTransactionColumns] = useState([])
    const [transactionGridCustomization, setTransactionGridCustomization] = useState({})

    const [transactionDetails, setTransactionDetails] = useState({
        externalId: '',
        show: false,
        readOnly: true,
        isIncome: false,
        property: '',
        defaultCurrency: '',
    })

    const [paymentDetails, setPaymentDetails] = useState({
        externalId: '',
        show: false,
        paymentExternalId: "",
        date: null
    })

    const [cancelTransaction, setCancelTransaction] = useState({
        externalId: '',
        show: false
    })

    const [isDoubleClick, setIsDoubleClick] = useState(false)
    const [editPaymentSettings, setEditPaymentSettings] = useState({
        show: false,
        externalId: '',
    })
    useImperativeHandle(componentReference, () => {
        return {
            addNewExpense(property, currency) {
                setTransactionDetails({
                    ...transactionDetails,
                    show: true,
                    externalId: '',
                    readOnly: false,
                    isIncome: false,
                    property: property,
                    defaultCurrency: currency
                })
            }, instance() {
                return gridInstance.current.instance()
            }
        };
    }, []);

    useEffect(() => {
        if (columns.length === 0) return
        setTransactionData(data)
        setTransactionGridCustomization(gridCustomization)
        let cols = columns
        let index = cols.findIndex(x => x.dataField === "Temp_Paid")
        if (index > -1) {
            cols[index]["editCellTemplate"] = "tempPaidTemplate";
            if (income && !expense) {
                cols[index]["caption"] = "Received"
                cols[index]["toolTip"] = "Received"
            } else if (!income && expense) {
                cols[index]["caption"] = "Paid"
                cols[index]["toolTip"] = "Paid"
            }
        }
        index = cols.findIndex(x => x.dataField === "Remarks")
        if (index > -1 && !tenancyContract && !purchasePayment) {
            cols[index]["editCellTemplate"] = "remarksTemplate";
        }
        setTransactionColumns(columns)
    }, [data, columns, gridCustomization, height, tenancyContract, purchasePayment])

    const filterValChanged = e => {
        let test = e;
    }

    const contextMenu = e => {
        if (!e.row) return;
        if (e.row.rowType !== 'data') return
        e.items = []
        e.component.selectRows(e.row.key)

        //View Details
        e.items.push({
            text: "View Details",
            icon: "eye",
            template: "contextMenuTemplate",
            onClick: function () {
                if (e.row.key.includes("NNNN"))
                    return alert("Please save unsaved transaction before performing this action.", "Warning!")
                setTransactionDetails({
                    ...transactionDetails,
                    show: true,
                    externalId: e.row.key,
                    readOnly: true,
                    isIncome: e.row.data["Income"],
                    property: e.row.data["Property"],
                    defaultCurrency: e.row.data["Currency"]
                })
            }
        })

        //Edit Details
        e.items.push({
            text: "Edit Details",
            icon: "edit",
            template: "contextMenuTemplate",
            onClick: function () {
                if (e.row.key.includes("NNNN"))
                    return alert("Please save unsaved transaction before performing this action.", "Warning!")
                setTransactionDetails({
                    ...transactionDetails,
                    show: true,
                    externalId: e.row.key,
                    readOnly: false,
                    isIncome: e.row.data["Income"],
                    property: e.row.data["Property"],
                    defaultCurrency: e.row.data["Currency"]
                })
            }
        })

        //Record Payment
        e.items.push({
            text: "Record Payment",
            icon: "notification-text",
            template: "contextMenuTemplate",
            onClick: function () {
                if (e.row.key.includes("NNNN"))
                    return alert("Please save unsaved transaction before performing this action.", "Warning!")
                if (e.row.data["Paid"]) {
                    return alert("Transaction already paid!", "Warning!")
                }
                setPaymentDetails({
                    ...paymentDetails,
                    show: true,
                    externalId: e.row.key,
                    paymentExternalId: "",
                    date: e.row.data["Temp_PaidDate"]
                })
            }
        })

        //View Associated Tenancy Contract
        // e.items.push({
        //     text: "View Associated Tenancy Contract",
        //     icon: "clipboard",
        //     template: "contextMenuTemplate",
        //     onClick: function () {
        //
        //     }
        // })

        //Cancel Transaction
        e.items.push({
            text: "Cancel Transaction",
            icon: "close",
            template: "contextMenuTemplate",
            onClick: function () {
                if (e.row.key.includes("NNNN"))
                    return alert("Please save unsaved transaction before performing this action.", "Warning!")
                if (e.row.data["Paid"]) {
                    return alert("Transaction already completed.", "Warning!")
                }
                if (e.row.data["Cancelled"]) {
                    return alert("Transaction already cancelled.", "Warning!")
                }

                confirm("Are you sure you wish to cancel the selected transaction?", "Warning!").then(resp => {
                    if (resp) {
                        setCancelTransaction({
                            ...cancelTransaction,
                            externalId: e.row.key,
                            show: true
                        })
                    }
                })
            }
        })

        //DELETE
        if (tenancyContract || purchasePayment) {
            e.items.push({
                text: "Delete Transaction",
                icon: "trash",
                template: "contextMenuTemplate",
                onClick: function () {
                    if (purchasePayment)
                        props.deletePurchasePaymentTransaction(e)
                    if (tenancyContract)
                        props.deleteContractTransaction(e)
                }
            })
        }
    }

    const componentUpdated = () => {
        props.isUpdated()
    }

    const editingStart = e => {
        if (!tenancyContract && !purchasePayment) {
            if (e.column.dataField === "Remarks") {
                if (!isDoubleClick) {
                    e.component.selectRows(e.key)
                    e.cancel = true;
                }
                if (isDoubleClick) {
                    setIsDoubleClick(false);
                }
            }
        }
        // if (tenancyContract || purchasePayment) {
        //     if (e.column.dataField !== "Remarks" && e.column.dataField !== "Reference") {
        //         if (e.data["Paid"] || e.data["Cancelled"])
        //             e.cancel = true;
        //     }
        // }
    }

    const cellDoubleClick = e => {
        if (e.column.dataField === "Remarks" && !tenancyContract && !purchasePayment) {
            setIsDoubleClick(true)
            e.component.editCell(e.rowIndex, e.columnIndex)
        }
    }

    const rowClickEvent = e => {
        if (e.data["externalId"].includes("NNNN")) return
        popoverRef.current.instance().option("target", e.rowElement)
        popoverRef.current.instance().show();
    }

    const RenderPopoverTemplate = () => {
        const changeColor = (colorCode) => {
            let selectedRowId = gridInstance.current.instance().getSelectedRowKeys()[0];

            let params = new URLSearchParams({
                objectName: "Transaction",
                externalId: selectedRowId,
                color: colorCode,
                remove: colorCode === null
            })
            apiClient
                .get('/Shared/UpdateObjectColor?' + params)
                .then(response => {
                    try {
                        LoaderService.setData(false)
                        let resp = JSON.parse(response.data)
                        if (resp[0].response) {
                            let index = transactionData.findIndex(x => x.externalId === selectedRowId)
                            if (index > -1) {
                                transactionData[index]["Color"] = colorCode
                                setTransactionData(transactionData)
                                gridInstance.current.instance().refresh()
                            }
                        } else {
                            return alert(resp[0].message, 'Warning!')
                        }
                        LoaderService.setData(false)
                    } catch (e) {
                        LoaderService.setData(false)
                        return alert(response.data, 'Error!')
                    }

                })
                .catch(err => {
                    LoaderService.setData(false)
                    console.log(err)
                    return alert(err, 'Error!')
                })
        }
        return (
            <>
                <span className="dot dot-color1" onClick={() => changeColor("#D2E2FF")}></span>
                <span className="dot dot-color2" onClick={() => changeColor("#FEE8B3")}></span>
                <span className="dot dot-color3" onClick={() => changeColor("#B1E3CE")}></span>
                <span className="dot dot-color4" onClick={() => changeColor("#D4D4D4")}></span>
                <span className="dot dot-color5" onClick={() => changeColor("#FFCFAF")}></span>
                <span className="dot dot-color6" onClick={() => changeColor("#EDD7FF")}></span>
                <span className="dot dot-color7" onClick={() => changeColor("#FFA7A7")}></span>
                <span className="dot dot-none" onClick={() => changeColor(null)}> </span>
            </>
        )
    }

    const cellPrepared = e => {
        cl.setObjectColor(e)
        cl.setHeaderTooltip(e)
        if (e.rowType === 'data') {
            if (e.row.data["Cancelled"]) {
                e.cellElement.style.color = 'red';
                e.cellElement.style.textDecoration = 'line-through';
            }
            if (tenancyContract || purchasePayment) {
                if (e.component.option("editing.allowUpdating") && !e.row.data["Cancelled"]) {
                    if (!e.column.allowEditing && e.rowType === 'data') {
                        if (e.column.hasOwnProperty("type")) return
                        e.cellElement.style.background = 'rgba(243,243,244,0.7)';
                        e.cellElement.style.color = 'black';
                    }
                }
            }
        }
        if (e.column.type === 'detailExpand' && e.rowType === 'data' && !e.row.data['HasPayments']) {
            e.cellElement.style.visibility = 'hidden'
        }
    }

    const rowExpanding = e => {
        e.component.collapseAll(-1)
    }

    const rowValidating = e => {
        if (tenancyContract || purchasePayment) {
            props.rowValidatingEvent(e)
        }
    }

    const TempPaidColumn = (props) => {
        const onValueChanged = useCallback(
            (e) => {
                if (!e.event) return
                if (props.data.externalId.includes("NNNN"))
                    return alert("Please save unsaved transaction before performing this action.", "Warning!")
                LoaderService.setData(true)
                let params = new URLSearchParams({
                    externalId: props.data.externalId,
                })
                apiClient
                    .get('/Transactions/MarkTransactionAsTempPaid?' + params)
                    .then(response => {
                        try {
                            LoaderService.setData(false)
                            let resp = JSON.parse(response.data)
                            if (resp[0].response) {
                                cl.showSuccessToast()
                                // props.setValue(e.value);
                                props.component.cellValue(props.rowIndex, props.column.dataField, e.value)
                                props.component.refresh()
                                // componentUpdated()
                            } else {
                                // props.setValue(e.previousValue);
                                props.component.cellValue(props.rowIndex, props.column.dataField, e.previousValue)
                                props.component.refresh()
                                return alert(resp[0].message, 'Warning!')
                            }
                        } catch (e) {
                            LoaderService.setData(false)
                            return alert(response.data, 'Error!')
                        }

                    })
                    .catch(err => {
                        LoaderService.setData(false)
                        console.log(err)
                        return alert(err, 'Error!')
                    })
            },
            [props],
        );
        return (<Switch
            switchedOnText=""
            switchedOffText=""
            value={props.value}
            onValueChanged={onValueChanged}
        />)
    }

    const PaymentHistoryMasterTable = props => {
        const [data, setData] = useState([])
        const [columns, setColumns] = useState([])
        const [customization, setCustomization] = useState({})
        let spanString = 'TransactionPayments'


        useEffect(() => {
            let params = new URLSearchParams({
                externalId: props.key,
            })
            apiClient
                .get('/Transactions/GetTransactionPaymentHistory?' + params)
                .then(response => {
                    try {
                        let data = JSON.parse(response.data)
                        let settings = data[1]['Settings']
                        setData(data[0]['Data'])
                        setColumns(settings[0][spanString][0]['Columns'])
                        setCustomization(settings[0][spanString][3]['GridCustomization'][0])
                        LoaderService.setData(false)
                    } catch (e) {
                        LoaderService.setData(false)
                        return alert(response.data, 'Error!')
                    }
                })
                .catch(err => {
                    LoaderService.setData(false)
                    console.log(err)
                    return alert(err, 'Error!')
                })
        }, [])

        const cellPrepared = e => {
            cl.setHeaderTooltip(e)
            cl.setColumnColorsFromVM(e)
        }

        const buttons = [{
            text: 'Edit',
            icon: 'customEdit',
            onClick: function (e) {
                debugger
                setPaymentDetails({
                    ...paymentDetails,
                    show: true,
                    externalId: props.key,
                    paymentExternalId: e.row.key,
                    date: props.data["Temp_PaidDate"]
                })
            }
        }]

        return (
            <>
                <DataGrid
                    dataSource={data}
                    {...customization}
                    onCellPrepared={cellPrepared}
                    height={300}
                    keyExpr="externalId"
                >
                    {columns.map((column, i) => (
                        cl.generateReactColumnsFromJson(column, i)
                    ))}
                    <Column
                        type={"buttons"}
                        buttons={buttons}
                        width={"5%"}
                        showInColumnChooser={false}
                        actionColumn={true}
                    ></Column>
                    <Template name="contextMenuTemplate" render={ContextMenuTemplate}/>
                    <Template name="myTemplate" render={MoreOptionsButton}/>
                </DataGrid>
            </>
        )
    }

    return (
        <>
            <Popover
                ref={popoverRef}
                showEvent="dxclick"
                hideOnOutsideClick={true}
                width={180}
                contentRender={RenderPopoverTemplate}
            >
            </Popover>
            <DataGrid
                ref={gridInstance}
                dataSource={transactionData}
                defaultColumns={transactionColumns}
                {...transactionGridCustomization}
                onContextMenuPreparing={contextMenu}
                onFilterValueChange={filterValChanged}
                keyExpr="externalId"
                height={height}
                onEditingStart={editingStart}
                onCellDblClick={cellDoubleClick}
                onRowClick={rowClickEvent}
                onCellPrepared={cellPrepared}
                onInitNewRow={props.newRowAdded}
                onRowValidating={rowValidating}
                onRowExpanding={rowExpanding}
            >
                {transactionColumns.map((column, i) => (
                    cl.generateReactColumnsFromJson(column, i)
                ))}
                {transactionColumns.map((column, i) => (
                    cl.generateActionButtonColumn(column)
                ))}
                {!income && !tenancyContract && !expense && !purchasePayment && !propertyExpenses ?
                    <MasterDetail enabled={true} render={PaymentHistoryMasterTable}/> : <></>}
                <Template name="contextMenuTemplate" render={ContextMenuTemplate}/>
                <Template name="myTemplate" render={MoreOptionsButton}/>
                <Template name="tempPaidTemplate" render={TempPaidColumn}/>
                <Template name="remarksTemplate" render={RemarksColumn}/>
                <Template name="statusTemplate" render={StatusColumn}/>
                <Template name="IncomeExpenseTemplate" render={GroupNameColumn}/>
            </DataGrid>
            <ManageTransactionDetails
                externalId={transactionDetails.externalId}
                show={transactionDetails.show}
                readOnly={transactionDetails.readOnly}
                isIncome={transactionDetails.isIncome}
                property={transactionDetails.property}
                defaultCurrency={transactionDetails.defaultCurrency}
                handleClose={() => {
                    setTransactionDetails({
                        ...transactionDetails,
                        show: false
                    })
                }}
                isUpdated={() => {
                    componentUpdated()
                }}
            ></ManageTransactionDetails>
            <RecordPayment
                externalId={paymentDetails.externalId}
                show={paymentDetails.show}
                paymentExternalId={paymentDetails.paymentExternalId}
                date={paymentDetails.date}
                handleClose={() => {
                    setPaymentDetails({
                        ...paymentDetails,
                        show: false
                    })
                }}
                isUpdated={() => {
                    componentUpdated()
                }}
            ></RecordPayment>
            <CancelTransaction
                externalId={cancelTransaction.externalId}
                show={cancelTransaction.show}
                handleClose={() => {
                    setCancelTransaction({
                        ...cancelTransaction,
                        show: false
                    })
                }}
                isUpdated={() => {
                    componentUpdated()
                }}
            ></CancelTransaction>
        </>
    )
})

export default TransactionsComponent;

const RemarksColumn = (props) => {
    const onValueChanged = useCallback(
        (e) => {
            if (!e.event) return
            LoaderService.setData(true)
            let params = new URLSearchParams({
                externalId: props.data.externalId,
                remark: e.value
            })
            apiClient
                .get('/Transactions/UpdateTransactionRemark?' + params)
                .then(response => {
                    try {
                        LoaderService.setData(false)
                        let resp = JSON.parse(response.data)
                        if (resp[0].response) {
                            cl.showSuccessToast()
                            props.setValue(e.value);
                            props.component.refresh()
                        } else {
                            props.setValue(e.previousValue);
                            props.component.refresh()
                            return alert(resp[0].message, 'Warning!')
                        }
                    } catch (e) {
                        LoaderService.setData(false)
                        return alert(response.data, 'Error!')
                    }

                })
                .catch(err => {
                    LoaderService.setData(false)
                    console.log(err)
                    return alert(err, 'Error!')
                })
        },
        [props],
    );

    return (<TextArea value={props.value} onValueChanged={onValueChanged}/>)
}

const StatusColumn = (props) => {
    if (props.data["Cancelled"]) { //CANCELLED
        return (
            <label key={props.key} className="form-check-label" style={{textDecoration: 'line-through', color: 'red'}}
                   htmlFor="ps-na"><span
                className="status-color"
            ></span>{props.data["Status"]}</label>)
    } else if (props.data["Paid"]) { //COMPLETED
        return (<label key={props.key} className="form-check-label" htmlFor="ps-unoccupied"><span
            className="status-color occupied"></span>{props.data["Status"]}</label>)
    } else if (props.data["Overdue"]) { //OVERDUE
        return (<label key={props.key} className="form-check-label" htmlFor="ps-overdue"><span
            className="status-color overdue"></span>{props.data["Status"]}</label>)
    } else if (props.data["Due"]) {//DUE
        return (<label key={props.key} className="form-check-label" htmlFor="ps-na"><span
            className="status-color na"></span>{props.data["Status"]}</label>)
    } else { //NOT DUE
        return (<label key={props.key} className="form-check-label" htmlFor="ps-occupied"><span
            className="status-color"></span>{props.data["Status"]}</label>)
    }
}

const GroupNameColumn = (props) => {
    if (props.data["Income"]) {
        return (<label key={props.key} className="form-check-label" htmlFor="ps-unoccupied"
                       title={"Income Transaction"}><span
            className="status-color occupied" style={{height: '10px', width: '10px'}}></span></label>)
    } else {
        return (
            <label key={props.key} className="form-check-label" htmlFor="ps-overdue" title={"Expense Transaction"}><span
                className="status-color danger" style={{height: '10px', width: '10px'}}></span></label>)
    }
}
