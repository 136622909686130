import Modal from "react-bootstrap/Modal";
import React, {useCallback, useEffect, useRef, useState} from "react";
import Button from "react-bootstrap/Button";
import apiClient, {baseUrl} from '../../services/axios/index'
import LoaderService from "../../RetroAssets.System/LoaderService";
import {alert} from "devextreme/ui/dialog";
import Form from "react-bootstrap/Form";
import {FileUploader, Template, TextArea, TextBox} from "devextreme-react";
import InputGroup from "react-bootstrap/InputGroup";
import {DataGrid} from "devextreme-react/data-grid";
import {ContextMenuTemplate, MoreOptionsButton} from "../../RetroAssets.System/MoreOptionsButton";
import cl from "../../RetroAssets.System/Utilities";

function ManageTenancyContractFileAttachments(props) {
    const {show, externalId, instantMode, readOnly} = props
    const [showModal, setShowModal] = useState(false);

    const faGridInstance = useRef(null);
    const searchBoxRef = useRef(null);
    let spanString = 'ItemDocumentList'
    const [gridData, setGridData] = useState([]);
    const [gridColumns, setGridColumns] = useState([]);
    const [gridCustomization, setGridCustomization] = useState({});
    const [editedData, setEditedData] = useState([])
    const [deletedItems, setDeletedItems] = useState([]);

    let fileAttachmentCounter = 0;
    let attachedFileCount = 0;
    let attachmentValueChangedEventExecuted = false;
    const attachmentButtonInstance = useRef(null);

    const modalClose = () => {
        props.handleClose()
        setShowModal(false);
        setEditedData([])
        setDeletedItems([])
    }

    useEffect(() => {
        if (!show) return
        if (!externalId) return
        LoaderService.setData(true);
        let params = new URLSearchParams({
            externalId: externalId
        })
        apiClient
            .get('/Property/GetTenancyContractFileAttachments?' + params)
            .then(response => {
                try {
                    let data = JSON.parse(response.data);
                    setGridData(data[0]["Data"])
                    let settings = data[1]["Settings"]
                    setGridColumns(settings[0][spanString][0]['Columns'])
                    setGridCustomization(settings[0][spanString][3]['GridCustomization'][0])
                    setShowModal(true)
                    LoaderService.setData(true);
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }
            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })

    }, [show])

    const clearFilter = () => {
        faGridInstance.current?.instance().clearFilter()
        faGridInstance.current?.instance().searchByText('')
        searchBoxRef.current?.instance().option('value', null)
    }

    const searchDocumentByText = e => {
        if (!e.event) return
        faGridInstance.current?.instance().searchByText(e.value ? e.value : null)
    }

    const fileUploaded = useCallback((e => {
        if (e.value.length === 0) return;
        e.value.forEach((val, index) => {
            let ind = `NNNN${index}`;
            gridData.push({
                Name: val.name,
                DocumentType: '',
                externalId: ind,
                DocumentLanguage: '',
                ExpiryDate: null,
                Status: '',
                isNew: true,
            });
            e.value[index]['uniqueId'] = ind;
        });
        setGridData(gridData);
        faGridInstance.current.instance().refresh();
    }), [gridData])

    function resetAttachmentControl() {
        attachmentValueChangedEventExecuted = true;
        attachmentButtonInstance.current.instance().reset();
        attachmentValueChangedEventExecuted = false;
        attachedFileCount = 0;
        fileAttachmentCounter = 0;
    }

    const executeAttachmentCommands = useCallback(() => {
        attachmentButtonInstance.current.instance()._isCustomClickEvent = true;
        attachmentButtonInstance.current.instance()['_$fileInput'][0].click();
    }, []);

    const selectionChanged = e => {
        return
        cl.singleSelectionCheckbox(e);
    };

    const refRowValidating = e => {
        setEditedData(cl.rowValidatingEvent(e, editedData));
    };

    const cellPrepared = e => {
        cl.setHeaderTooltip(e);
    };

    const addNewPhotos = () => {
        executeAttachmentCommands()
    }

    const saveData = () => {
        if (!instantMode) {
            props.isUpdated(gridData, spanString, editedData, deletedItems, attachmentButtonInstance.current.instance().option('value'))
            resetAttachmentControl();
            modalClose();
            setShowModal(false)
            return
        }
        let formData = new FormData();
        formData.append('externalId', externalId);
        formData.append('FileAttachmentData', JSON.stringify(gridData));
        formData.append(
            'FileAttachmentEditedData',
            JSON.stringify([{spanString: spanString, editedData: editedData}]),
        );
        formData.append('DeletedFileAttachments', JSON.stringify(deletedItems));
        let fa = attachmentButtonInstance.current.instance().option('value');
        fa.forEach(val => {
            formData.append(val['uniqueId'], val, val['uniqueId']);
        });
        apiClient
            .post('/Property/AddUpdateTenancyContractFileAttachments', formData)
            .then(response => {
                if (response) {
                    try {
                        LoaderService.setData(false);
                        let resp = JSON.parse(response.data);
                        resetAttachmentControl();
                        modalClose();
                        setShowModal(false)
                        if (resp[0].response) {
                            props.isInstantUpdated();
                        } else {
                            return alert(resp[0].message, 'Warning!');
                        }

                    } catch (e) {
                        resetAttachmentControl();
                        modalClose();
                        LoaderService.setData(false);
                        return alert(response.data, 'Error!');
                    }
                }
            })
            .catch(err => {
                LoaderService.setData(false);
                console.log(err);
            });

    }

    const downloadSelected = () => {
        let ids = faGridInstance.current.instance().getSelectedRowKeys();
        if (ids.length === 0) return alert("Please select one or more files to download.", "Warning!")
        LoaderService.setData(true)
        let params = new URLSearchParams({
            externalIds: ids.toString(),
            objectType: 'TenancyContract',
            rootObjectExternalId: externalId
        })

        apiClient
            .get('/Shared/GenerateDocumentsReport?' + params)
            .then(response => {
                try {
                    let resp = JSON.parse(response.data)
                    if (resp[0].response) {
                        let path = baseUrl() + resp[0]['message'];
                        let win = window.open(path, '_blank');
                        win.focus();
                    } else {
                        return alert(resp[0].message + '<br /><br /><i>[TIP: Most errors in file downloads are caused by popup blockers.' +
                            '<br />Kindly configure your browser to allow popups if errors persist.</i>', 'Warning!');
                    }
                    LoaderService.setData(false)
                } catch (e) {
                    LoaderService.setData(false)
                    return alert(response.data, 'Error!')
                }

            })
            .catch(err => {
                LoaderService.setData(false)
                console.log(err)
                return alert(err, 'Error!')
            })
    }

    return (<Modal show={showModal} onHide={modalClose} centered dialogClassName="modal-70w prop-img-slider">
            <Modal.Header closeButton className="border-0 pb-0 modal-header">
                <Modal.Title className="fs-5 fw-bold">
                    <span>MANAGE TENANCY CONTRACT ATTACHMENTS</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-3">
                <div className="py-2 tabs-border">
                    <div className="row tabsContent">
                        <div className="col-sm-12">
                            <div
                                className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center mb-2">
                                <h4 className="fs-4 fw-medium mb-3 mb-sm-0"></h4>
                                <div className="d-flex">
                                    <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                            onClick={clearFilter}>
                                        Clear Filter
                                    </Button>
                                    <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                            onClick={downloadSelected}>
                                        Download
                                    </Button>
                                    {readOnly ? <></> :
                                        <Button variant="outline-primary" className="flex-shrink-0 me-3"
                                                onClick={addNewPhotos}>
                                            Add Documents
                                        </Button>}
                                    <InputGroup className="me-3 search-input">
                                        <TextBox
                                            ref={searchBoxRef}
                                            placeholder="Search Documents..."
                                            mode="search"
                                            inputAttr={{class: "form-control"}}
                                            elementAttr={{class: "search-input input-group"}}
                                            width="100%"
                                            onValueChanged={searchDocumentByText}
                                        ></TextBox>
                                    </InputGroup>
                                </div>
                            </div>
                            <FileUploader
                                style={{display: 'none'}}
                                ref={attachmentButtonInstance}
                                multiple={true}
                                accept=".pdf, .xlsx, .xls, .doc, .docx, .txt, .jpg, .png, .bmp"
                                labelText=""
                                allowCanceling={false}
                                showFileList={false}
                                selectButtonText="Add Documents"
                                onValueChanged={fileUploaded}
                                uploadMode="useForm"
                            ></FileUploader>
                            <DataGrid
                                ref={faGridInstance}
                                keyExpr="externalId"
                                dataSource={gridData}
                                columns={gridColumns}
                                height={400}
                                onCellPrepared={cellPrepared}
                                onRowValidating={refRowValidating}
                                onSelectionChanged={selectionChanged}
                                {...gridCustomization}
                            >
                                <Template name="contextMenuTemplate" render={ContextMenuTemplate}/>
                                <Template name="myTemplate" render={MoreOptionsButton}/>
                            </DataGrid>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="pt-2">
                <>
                    <Button variant="outline-primary" className="flex-shrink-0 me-3"
                            onClick={saveData}>
                        {instantMode ? "Save" : "Add Files"}
                    </Button>
                    <Button variant="outline-primary" className="flex-shrink-0 me-3"
                            onClick={modalClose}>
                        Cancel
                    </Button>
                </>
            </Modal.Footer>
        </Modal>
    )
}

export default ManageTenancyContractFileAttachments;